import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Accordion, ArrowLeftIcon, BuildingIcon, Button, Dialog, Divider, Flex, FormDropdown, Pill, Segment, UserFriendsIcon } from '@fluentui/react-northstar'
import { useTranslation } from 'react-i18next'

import './AddFiltersModal.css'
import Counter from '../Counter/Counter'
import { autoSetBuilding, autoSetFloor, autoSetPlace, autoSetSite, defaultFunction, getAmenitieImage, getPlaces, getPlaceValue } from '../../common/utilities/utilities'
import {
  MarkerOutline,
  Floor
} from '../../components/Svgs/Svgs'


import { connect } from 'react-redux'
//import { MarkerOutline } from '../Svgs/Svgs'

const AddFiltersModal = props => {
  const {
    open = false,
    rooms = {},
    handleClose = defaultFunction,
    sites = [],
    handleApplyFilters = defaultFunction,
  } = props

  const { t } = useTranslation()
  const [site, setSite] = useState(-1)
  const [building, setBuilding] = useState(-1)
  const [floor, setFloor] = useState(-1)
  const [buildings, setBuildings] = useState([])
  const [floors, setFloors] = useState([])
  const [capacity, setCapacity] = useState(1)
  const [amenities, setAmenities] = useState([])
  const [roomsCapacity, setRoomsCapacity] = useState(1)
  const setPlaceMethods = {
    sites,
    buildings,
    floors,
    setSite,
    setBuildings,
    setBuilding,
    setFloors,
    setFloor
  }

  useEffect(() => {
    autoSetPlace(sites, setPlaceMethods)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeSite = (_, { highlightedIndex }) => autoSetSite(highlightedIndex, setPlaceMethods)
  const onChangeBuilding = (_, { highlightedIndex }) => autoSetBuilding(highlightedIndex, setPlaceMethods)
  const onChangeFloor = (_, { highlightedIndex }) => autoSetFloor(highlightedIndex, setPlaceMethods)

  useEffect(() => {
    const roomsCapacityList = []
    if (floor !== -1) {
      const prefilteredRooms = rooms.filter((room) => room.floor?.id === floor)
      prefilteredRooms?.forEach((room) => {
        if (!roomsCapacityList.includes(room.capacity) && room?.capacity !== 0)
          roomsCapacityList.push(room.capacity)
      })
    } else {
      rooms?.forEach((room) => {
        if (!roomsCapacityList.includes(room.capacity) && room?.capacity !== 0)
          roomsCapacityList.push(room.capacity)
      })
    }
    roomsCapacityList.sort((a, b) => a - b)
    setRoomsCapacity(roomsCapacityList)
  }, [rooms, floor])

  const getAllAmenities = numberOfAmenities => {
    let amenitiesComponents = []
    for (let i = 0; i < numberOfAmenities; i++) {
      const amenityIndex = i
      const isSelected = amenities.includes(amenityIndex);
      amenitiesComponents.push(
        <Pill
          key={i}
          size="small"
          rectangular
          appearance={isSelected ? 'filled' : 'outline'}
          onClick={() => handleChangeAmenities(amenityIndex)}
          content={
            <Flex gap="gap.smaller" vAlign='center'>
              {getAmenitieImage(i, t)}
              <label>{t(getAmenitieImage(i, t).props.title)}</label>
            </Flex>
          }
          className='amenity-pill'
        />
      )
    }
    return amenitiesComponents
  }

  const handleChangeCapacity = (capacity) => {
    setCapacity(capacity)
  }

  const handleChangeAmenities = (amenity) => {
    setAmenities(prevAmenities => {
      if (prevAmenities.includes(amenity)) {
        return prevAmenities.filter(a => a !== amenity)
      }
      return [...prevAmenities, amenity]

    })
  }

  const onApplyFilters = useCallback(() => {
    handleApplyFilters({
      site,
      building,
      floor,
      capacity,
      amenities,
      filtered: true
    })
  }, [site, building, floor, capacity, amenities, handleApplyFilters])

  const clearFilters = useCallback(() => {
    const site = -1
    const building = -1
    const floor = -1
    const capacity = 1
    const amenities = []

    setSite(site)
    setBuilding(building)
    setFloor(floor)
    setCapacity(capacity)
    setAmenities(amenities)

    handleApplyFilters({
      site,
      building,
      floor,
      capacity,
      amenities,
      filtered: false
    })
  }, [handleApplyFilters])

  const maxRoomCapacity = useMemo(() => roomsCapacity.length ? Math.max(...roomsCapacity) : 0, [roomsCapacity])

  return (
    <Dialog
      open={open}
      onCancel={handleClose}
      aria-labelledby="responsive-dialog-title"
      content={
        <Flex column gap='gap.medium'>
          <Flex
            hAlign='start'
            vAlign='center'
            gap='gap.small'
          >
            <Button
              text
              iconOnly
              onClick={handleClose}
            >
              <ArrowLeftIcon />
            </Button>
            <h3>{t('Add filter')}</h3>
          </Flex>
          <Flex>
            <Flex column gap='gap.small'>
              <Accordion
                defaultActiveIndex={[0]}
                panels={
                  [
                    {
                      title: t('Location filter'),
                      content: (
                        <Flex column gap='gap.medium' key="location">
                          <Flex gap="gap.small" vAlign='center'>
                            <MarkerOutline className='input-icon' />
                            <label className='form-label'>{t('site')}</label>
                            <FormDropdown
                              placeholder={t('select')}
                              onChange={onChangeSite}
                              items={getPlaces(sites)}
                              value={getPlaceValue(sites, site)}
                            />
                          </Flex>
                          <Flex gap="gap.small" vAlign='center'>
                            <BuildingIcon outline />
                            <label className='form-label'>{t('building')}</label>
                            <FormDropdown
                              placeholder={t('select')}
                              onChange={onChangeBuilding}
                              disabled={!site}
                              items={getPlaces(buildings)}
                              value={getPlaceValue(buildings, building)}
                            />
                          </Flex>
                          <Flex gap="gap.small" vAlign='center'>
                            <Floor className='input-icon' />
                            <label className='form-label'>{t('floor')}</label>
                            <FormDropdown
                              placeholder={t('select')}
                              onChange={onChangeFloor}
                              disabled={!building}
                              items={getPlaces(floors)}
                              value={getPlaceValue(floors, floor)}
                            />
                          </Flex>
                          <Flex gap='gap.small' vAlign='center'>
                            <UserFriendsIcon outline />
                            <label>{t('Capacity')}</label>
                            <Counter
                              initialValue={capacity || 1}
                              min={0}
                              max={999}
                              onChange={handleChangeCapacity}
                            />
                          </Flex>
                          <Segment
                            color={capacity > maxRoomCapacity ? 'red' : ''}
                            content={t('The room with the largest capacity is for N people', { maxRoomCapacity })}
                          />
                        </Flex>
                      )
                    }
                  ]
                }
              />
              <Divider />
              <Accordion
                defaultActiveIndex={[0]}
                panels={[{
                  title: t('Amenities filter'),
                  content: (
                    <Flex wrap gap="gap.small" key="amenities">
                      {getAllAmenities(9)}
                    </Flex>
                  )
                }]}
              />
            </Flex>
          </Flex>
          <Flex hAlign='end' gap='gap.small'>
            <Button
              onClick={clearFilters}
            >
              {t('Clear filters')}
            </Button>
            <Button      
              primary
              disabled={site === -1 && capacity === 0 && amenities.length === 0}
              onClick={onApplyFilters}
            >
              {t('Apply filters')}
            </Button>
          </Flex>
        </Flex>
      }
    />
  )
}

const mapStateToProps = state => {
  return {
    sites: state.workplace.sitesRooms,
  }
}


export default connect(mapStateToProps, null)(AddFiltersModal)