import React from 'react'

import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation
} from 'react-router-dom'

import * as microsoftTeams from '@microsoft/teams-js'

import { connect } from 'react-redux'

import Login from './Pages/Login/Login'
import LoadPage from './Pages/Load/Load'
import RedirectPage from './Pages/RedirectPage/RedirectPage'
//import Reservations from './Pages/Reservations/Reservations'
import ParkingReservation from './Pages/ParkingReservation/ParkingReservation'
import DeskReservation from './Pages/DeskReservation/DeskReservation'
import RoomReservation from './Pages/RoomReservation/RoomReservation'
import GlobalLoader from './GlobalLoader/GlobalLoader'
import GlobalAlert from './GlobalAlert/GlobalAlert'

import { clearAuth } from '../api/api'


function Auth({ children, profile }) {
  let location = useLocation()
  let { path } = useRouteMatch()

  if (!profile.email) {
    clearAuth()
    return (
      <Redirect
        to={`${path}/redirect`}
        state={{ from: location }}
      />
    )
  }
  return children
}

const mapStateToProps = state => {
  return { profile: state.profile }
}

const RequireAuth = connect(mapStateToProps)(Auth)

function Tab() {
  let { path } = useRouteMatch()
  microsoftTeams.app.initialize().then(() => {
    microsoftTeams.app.notifySuccess()
  }).catch((error) => {
    console.error("Error al inicializar la biblioteca de Teams", error)
  })

  //const RReservations = () => <RequireAuth><Reservations /></RequireAuth>
  const RParkingReservation = () => <RequireAuth><ParkingReservation /></RequireAuth>
  const RDeskReservation = () => <RequireAuth><DeskReservation /></RequireAuth>
  const RRoomReservation = () => <RequireAuth><RoomReservation /></RequireAuth>
  const RLoadPage = () => <RequireAuth><LoadPage /></RequireAuth>


  return (
    <>
      <GlobalLoader />
      <GlobalAlert />
      <Switch>
        <Route exact path={path} component={RedirectPage} />
        <Route path={`${path}/redirect`} component={RedirectPage} />
        <Route path={`${path}/load`} component={RLoadPage} />
        <Route path={`${path}/login`} component={Login} />
        <Route path={`${path}/parking-reservation`} component={RParkingReservation} />
        <Route path={`${path}/desk-reservation`} component={RDeskReservation} />
        <Route path={`${path}/room-reservation`} component={RRoomReservation} />
      </Switch>
    </>
  );
}

export default Tab
