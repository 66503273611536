import { Button, Flex } from "@fluentui/react-northstar"
import './RedirectPage.css'


function RedirectPage() {

  const goToWebApp = () => {
    const url = 'https://app.corporateexperience.parso.co/' 
    window.open(url, '_blank').focus()
  }

  return (
    <Flex
      className="RedirectPage"
      hAlign="center"
      column
    >
      <h1>Te damos la bienvenida</h1> 
      <p> Corporate Experience te ayudará a gestionar las reservas de tus espacios para los días que visitas la oficina.</p>
      <img
        width="442px"
        height="377px"
        src="/redirect.png"
        alt="redirect"
      />
      <Button onClick={goToWebApp} primary>
        Comenzar
      </Button>
    </Flex>
  )
}

export default RedirectPage