import React, { useState, useEffect, useCallback, useMemo } from 'react'

import {
  Form,
  FormDropdown,
  FormDatepicker,
  Flex,
  Button
} from '@fluentui/react-northstar'

import { DateTime } from 'luxon'

import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import { api } from '../../../api/api'

import { Types } from "../../../state/actionTypes"
import NewReservationLayout from '../../NewReservationLayout/NewReservationLayout'

import {
  errorCreateReservationI18n,
} from '../../../common/utilities/Dictionary'


import {
  formatDateToISOShort,
  getCurrentHour,
  isToday,
} from '../../../common/utilities/FormatDate'

import {
  MarkerOutline,
  ClockOutline,
  CalendarOutline,
  StickerOutline,
  CarOutline
} from '../../Svgs/Svgs'

import {
  DEFAULT_OFFSET_MIN_RESERVATION_MINUTES,
  HOURS,
  EIGHT_AM
} from '../../../variables'

import {
  _dispatch as dispatch,
  defaultCatch,
  defaultSuccess
} from '../../../state/actions'


import Hide from '../../Hide/Hide'
const today = new Date()

function ParkingReservation(props) {
  const {
    dispatch,
    defaultSuccess,
    defaultCatch,
    minReservationTime,
    askForParkingReservationReason,
    askForParkingReservationVehicle,
    askForParkingReservationEntryTime
  } = props

  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const parkingDesk = useMemo(() => location?.state?.parkingDesk || false, [location])
  const parkingRoom = useMemo(() => location?.state?.parkingRoom || false, [location])
  const maxFutureDays = useMemo(() => location?.state?.maxFutureDays || 0, [location])

  const reasons = [
    t('private meeting'),
    t('workday'),
    t('training'),
    t('special event'),
    t('others')
  ]

  const [parkingLots, setParkingLots] = useState([])
  const [vehicles, setVehicles] = useState([])

  const [parking_lot, setParkingLot] = useState(-1)
  const [date, setDate] = useState(today)

 const getDefaultEntryTime = useCallback((_date = date) => {
    if (isToday(_date)) {
     const minutes = minReservationTime + DEFAULT_OFFSET_MIN_RESERVATION_MINUTES  
     const minHour = getCurrentHour({ plusHour: 0, plusMinutes: minutes}) 
     return minHour
    }
    return EIGHT_AM
  }, [date, minReservationTime])

  const [entry_time, setEntryTime] = useState(getDefaultEntryTime())
  const [reason, setReason] = useState(t('workday'))
  const [vehicle_id, setVehicleId] = useState(-1)

  const onCancel = () => history.push('/')

  const setLoading = useCallback(loading => {
    dispatch({
      type: Types.SET_BACKDROP_LOADING,
      payload: { loading }
    })
  }, [dispatch])


  const fetchVehicles = useCallback(({ fromModal }) => {
    setLoading(true)
    api.get('/vehicles')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setVehicles(data.vehicles)
        if (data.vehicles.length === 1) {
          setVehicleId(data.vehicles[0].id)
        }
      })
      .catch(error => defaultCatch(error, history))
  }, [defaultCatch, defaultSuccess, history, setLoading])


  const fetchParkingLots = useCallback(() => {
    setLoading(true)
    api.get('/parking_lots')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setParkingLots(data.parking_lot)
        if (data.parking_lot.length === 1) {
          setParkingLot(data.parking_lot[0].id)
        }
        fetchVehicles({})
      })
      .catch(error => defaultCatch(error, history))
  }, [defaultCatch, defaultSuccess, fetchVehicles, history, setLoading])

  useEffect(() => {
    fetchParkingLots()
  }, [fetchParkingLots])

  const isEnabled = useCallback(() => {
    return (
      parking_lot > -1 &&
      entry_time &&
      date &&
      (!askForParkingReservationReason ||  reason) &&
      (!askForParkingReservationVehicle ||  vehicle_id > -1)
    )
  }, [askForParkingReservationReason, askForParkingReservationVehicle, date, entry_time, parking_lot, reason, vehicle_id])

  const getMaxDateParking = useCallback(() => {
    return DateTime
      .local()
      .plus({ days: maxFutureDays })
      .toJSDate()
  }, [maxFutureDays])

  const getParkingLots = useCallback(() => parkingLots.map(p => p.name), [parkingLots])
  const getVehicles = useCallback(() => vehicles.map(v => v.license_plate), [vehicles])

  const getParkingLotValue = useCallback(
    () => (parkingLots.find(p => p.id === parking_lot) || {}).name,
    [parkingLots, parking_lot]
  )

  const updateDate = (_, { value }) => {
    setDate(value)
    setEntryTime(getDefaultEntryTime(value))  
  }
  const updateReason = (_, { value }) => setReason(value)
  const updateEntryTime = (_, { value }) => setEntryTime(value)

  const updateParkingLot = (_, { highlightedIndex }) => {
    const item = parkingLots[highlightedIndex]
    setParkingLot(item.id)
  }

  const updateVehicle = (_, { highlightedIndex }) => {
    const item = vehicles[highlightedIndex]
    setVehicleId(item.id)
  }

  const saveReservation = () => {
    setLoading(true)
    const payload = {
      parking_reservation: {
        parking_lot_id: parking_lot,
        parking_date: formatDateToISOShort(date),
        entry_time,
        reason
      }
    }

    if (askForParkingReservationVehicle) {
      payload.parking_reservation.vehicle_id = vehicle_id
    }

    api.post('/parking_reservations/', payload)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success && data.message) {
          dispatch({
            type: Types.SET_ALERT_DATA,
            payload: {
              open: true,
              content: errorCreateReservationI18n(data.message),
              danger: true,
              autoHideDuration: 4000
            }
          })
        } else {
          
          if (parkingDesk) {
            history.push({
              pathname: '/tab/desk-reservation',
              state: {
                previousDate: date,
                previousEntryTime: entry_time,
                maxFutureDays
              },
            })
          } else if (parkingRoom) {
            history.push({
              pathname: '/tab/room-reservation',
              state: {
                previousDate: date,
                previousEntryTime: entry_time,
                maxFutureDays
              },
            })
          } else history.push('/')
          
          dispatch({
            type: Types.SET_ALERT_DATA,
            payload: {
              open: true,
              content: t('success parking reservation'),
              success: true,
              autoHideDuration: 4000
            }
          })
        }
      })
      .catch(error => defaultCatch(error, history))
  }

  return (
    <NewReservationLayout
      title={t('New reservation: parking')}
      onCancel={onCancel}>
      <div
        className="NewReservation"
        id="new-reservation-parking"
      >
        <Form>
          <Hide hide={parkingLots.length === 1}>
            <Flex gap="gap.small">
              <MarkerOutline className='input-icon' />
              <FormDropdown
                onChange={updateParkingLot}
                placeholder={t('parking')}
                items={getParkingLots()}
                value={getParkingLotValue()}
                inverted
              />
            </Flex>
          </Hide>
          <Flex gap="gap.small">
            <CalendarOutline className='input-icon' />
            <FormDatepicker
              onDateChange={updateDate}
              minDate={today}
              selectedDate={date}
              maxDate={getMaxDateParking()}
              inputPlaceholder={t('date')}
              disabled={parking_lot === -1}
              allowManualInput={false}
              inputOnly
            />
            <Hide movil hide={!askForParkingReservationEntryTime}>
              <FormDropdown
                items={HOURS}
                placeholder={t('hour')}
                disabled={!date || parking_lot === -1}
                onChange={updateEntryTime}
                value={entry_time}
                inverted
              />
            </Hide>
          </Flex>
          <Hide web hide={!askForParkingReservationEntryTime}>
            <Flex gap="gap.small">
              <ClockOutline className='input-icon' />
              <FormDropdown
                items={HOURS}
                placeholder={t('hour')}
                disabled={!date || parking_lot === -1}
                onChange={updateEntryTime}
                value={entry_time}
                inverted
              />
            </Flex>
          </Hide>
          <Hide hide={!askForParkingReservationReason}>
            <Flex gap="gap.small">
              <StickerOutline className='input-icon' />
              <FormDropdown
                items={reasons}
                placeholder={t('reason')}
                disabled={!entry_time}
                onChange={updateReason}
                value={reason}
                inverted
              />
            </Flex>
          </Hide>
          <Hide hide={!askForParkingReservationVehicle}>
            <Flex gap="gap.small">
              <CarOutline className='input-icon' />
              <FormDropdown
                items={getVehicles()}
                placeholder={t('vehicle')}
                disabled={(!askForParkingReservationReason && reason === -1) || vehicles.length === 0}
                onChange={updateVehicle}
                inverted
              />
            </Flex>
          </Hide>
          <Flex gap="gap.small" hAlign="end">
            <Button
              disabled={!isEnabled()}
              content={t('confirm reservation')}
              primary
              onClick={saveReservation}
            />
          </Flex>
        </Form>
      </div>
    </NewReservationLayout>
  )
}

const mapStateToProps = state => {
  return {
    askForParkingReservationReason: state.profile.company.ask_for_parking_reservation_reason,
    askForParkingReservationEntryTime: state.profile.company.ask_for_parking_reservation_entry_time,
    askForParkingReservationVehicle: state.profile.company.ask_for_parking_reservation_vehicle,
    minReservationTime: state.profile.company.min_reservation_time
  }
}

const mapDispatchToProps = {
  dispatch,
  defaultSuccess,
  defaultCatch
}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingReservation)
