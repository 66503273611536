import { Types } from '../actionTypes'
import { clearAuth } from '../../api/api'

const initialState = {
  id: 0,
  first_name: '',
  last_name: '',
  email: '',
  card_number: '',
  tag_number: '',
  beacon: '',
  team: [],
  group: [],
  preferentialAccess: false,
  domain: 'public',
  goHome: 0,
  enabled_parking: false,
  enabled_workplace: false,
  enabled_room_reservations: false,
  has_accepted_privacy_policy: false,
  company: {
    max_future_days_to_reserve: 60,
    ask_for_parking_reservation_vehicle: true,
    ask_for_parking_reservation_reason: true,
    ask_for_desk_reservation_reason: true,
    ask_for_parking_reservation_entry_time: true,
    ask_for_desk_reservation_entry_time: true,
    min_reservation_time: 0
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_DOMAIN:
      const domain = action.payload
      state.domain = domain
      localStorage.setItem('domain', domain)
      return state
    case Types.SET_AUTH:
      const {
        id = 0,
        first_name = '',
        last_name = '',
        email = '',
        card_number = '',
        tag_number = '',
        beacon = '',
        has_accepted_privacy_policy = false,
        preferentialAccess = false
      } = action.payload.data

      const goHome = state.goHome + 1
      const company = action.payload.company
      const groups = action.payload.groups
      const team = action.payload.workplace_group
     
      const {
        enabled_visits,
        enabled_parking,
        enabled_workplace,
        enabled_parking_schedule,
        enabled_room_reservations,
        enabled_manual_checkin: enabled_desk_reservations_manual_checkin,
        enabled_room_reservations_manual_checkin,
        next_assistance_date
      } = action.payload

      let {
        enabled_parking_reservations_manual_checkin,
      } = action.payload

      if (company.enabled_group_settings) {
        enabled_parking_reservations_manual_checkin = groups.some(g => g.enabled_parking_reservations_manual_checkin || false)
      }

      return {
        ...state,
        id,
        team,
        email,
        groups,
        beacon,
        goHome,
        company,
        last_name,
        tag_number,
        first_name,
        card_number,
        enabled_parking,
        enabled_workplace,
        preferentialAccess,
        enabled_room_reservations,
        has_accepted_privacy_policy,
        enabled_parking_schedule,
        enabled_desk_reservations_manual_checkin,
        enabled_parking_reservations_manual_checkin,
        enabled_room_reservations_manual_checkin,
        enabled_visits,
        next_assistance_date
      }
    case Types.CLEAN_AUTH:
      clearAuth()
      return initialState
    case Types.SET_TRUE_HAS_ACCEPTED_PRIVACY_POLICY:
      return { ...state, has_accepted_privacy_policy: true }
    default:
      return state
  }
}

export default reducer;