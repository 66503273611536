import React, { useState, useEffect, useCallback, useMemo } from 'react'

import {
  Form,
  FormMessage,
  FormDropdown,
  FormDatepicker,
  Flex,
  Checkbox,
  Dialog,
  Input,
  CloseIcon,
  Button
} from '@fluentui/react-northstar'

import { DateTime } from 'luxon'

import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import { api } from '../../../api/api'

import { Types } from "../../../state/actionTypes"
import NewReservationLayout from '../../NewReservationLayout/NewReservationLayout'
import FloorMap from '../../FloorMap/FloorMap'


import {
  errorCreateReservationWorkplaceI18n,
} from '../../../common/utilities/Dictionary'

import {
  formatDateToISOShort,
  getCurrentHour,
  getAllTime,
  isToday
} from '../../../common/utilities/FormatDate'

import { autoSetBuilding, autoSetFloor, autoSetPlace, autoSetSite, getPlaces, getPlaceValue } from '../../../common/utilities/utilities'

import {
  MarkerOutline,
  ClockOutline,
  CalendarOutline,
  StickerOutline,
  DeskDot,
  Building,
  Place,
  Floor
} from '../../Svgs/Svgs'

import {
  _dispatch as dispatch,
  setLoading,
  defaultSuccess,
  defaultCatch,
} from '../../../state/actions'

import {
  DEFAULT_OFFSET_MIN_RESERVATION_MINUTES,
  HOURS,
  EIGHT_AM,
  END_DAY
} from '../../../variables'

import Hide from '../../Hide/Hide'
import './DeskReservation.css'


const today = new Date()

function DeskReservation(props) {
  const {
    sites = [],
    dispatch,
    setLoading,
    defaultSuccess,
    defaultCatch,
    askForDeskReservationEntryTime,
    askForDeskReservationReason,
    workplaceDefaultEntryTime,
    workplaceDefaultExitTime,
    minReservationTime
  } = props

  const { t } = useTranslation()
    
  const reasons = [
    t('private meeting'),
    t('workday'),
    t('training'),
    t('special event'),
    t('others')
  ]
  
  const history = useHistory()
  const location = useLocation()
  const initDate = location?.state?.previousDate || today 
  const [date, setDate] = useState(initDate)
  const maxFutureDays = useMemo(() => location?.state?.maxFutureDays || 0, [location])

  const getDefaultEntryTime = useCallback(date => {
    const isTodayValue =  isToday(date || initDate)
    if (workplaceDefaultEntryTime && !isTodayValue) 
      return workplaceDefaultEntryTime

    if (isTodayValue) {
      const minutes = minReservationTime + DEFAULT_OFFSET_MIN_RESERVATION_MINUTES
      const minHour = getCurrentHour({ plusHour: 0, plusMinutes: minutes})
      return workplaceDefaultEntryTime && 
             workplaceDefaultEntryTime >= minHour ? 
              workplaceDefaultEntryTime :
              minHour
    }
    return EIGHT_AM;
  }, [initDate, workplaceDefaultEntryTime, minReservationTime])
  
  const [entry_time, setEntryTime] = useState(getDefaultEntryTime(date))

  const getDefaultExitTime = useCallback(() => {
    if (!askForDeskReservationEntryTime) return END_DAY
    if (workplaceDefaultExitTime && workplaceDefaultExitTime > entry_time) return workplaceDefaultExitTime
    return END_DAY
  }, [askForDeskReservationEntryTime, entry_time, workplaceDefaultExitTime])
  
  const [exit_time, setExitTime] = useState(getDefaultExitTime())
  const [exitTimeError, setExitTimeError] = useState('')

  const [site, setSite] = useState()
  const [building, setBuilding] = useState()
  const [buildings, setBuildings] = useState([])
  const [floor, setFloor] = useState()
  const [floors, setFloors] = useState([])
  const [floorMapId, setFloorMapId] = useState()

  const [desk, setDesk] = useState('')
  const [selectedMarker, setSelectedMarker] = useState('')
  const [allTimeValue, setAllTimeValue] = useState(false)
  const [reason, setReason] = useState(t('workday'))
  const [markers, setMarkers] = useState([])
  const [floorImage, setFloorImage] = useState({})
  const [openMap, setOpenMap] = useState(false)
  const onCancel = () => history.push('/')
    const setPlaceMethods = {
    sites,
    buildings,
    floors,
    setSite,
    setBuildings,
    setBuilding,
    setFloors,
    setFloor,
    setFloorMapId
  }

  
  useEffect(() => {
    autoSetPlace(sites, setPlaceMethods)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchFloorsImage = useCallback(params => {
    const { id, width, height } = params
    setLoading(true)
    api.get(`/workplace/floor_map/serve/${id}`, { responseType: "blob" })
      .then(defaultSuccess)
      .then(({ data }) => {
        const blob = URL.createObjectURL(data)
        setFloorImage({ url: blob, size: [width, height] })
      })
      .catch(error => defaultCatch(error, history))
  }, [defaultCatch, defaultSuccess, setLoading, history])

  const fetchFloorMap = useCallback((floorMapId, date) => {
    if (!floorMapId) return

    setLoading(true)   
    const formatDate = formatDateToISOShort(date)
    const params = {
      date: formatDate,
      start_hour: entry_time,
      end_hour: exit_time
    }

    api.get(`/workplace/floor_map/${floorMapId}`, { params })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setMarkers(data.floor_map.desks)
        fetchFloorsImage(data.floor_map)
      })
      .catch(error => defaultCatch(error, history))
  }, [defaultCatch, defaultSuccess, entry_time, exit_time, fetchFloorsImage, setLoading, history])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchFloorMap(floorMapId, date), [floorMapId, date])

  useEffect(() => {
    setExitTimeError(
      (entry_time >= exit_time) ?
        t('Check out time must be greater than check in time') :
        ''
    )
  }, [entry_time, exit_time, t])

  const isEnabled = useCallback(() => {
    return (
      date &&
      entry_time &&
      exit_time &&
      site &&
      building &&
      floor &&
      desk
    )
  }, [building, date, desk, exit_time, entry_time, floor, site])


  const getMaxDateWorkplace = useCallback(() => {
    return DateTime
      .local()
      .plus({ days: maxFutureDays })
      .toJSDate()
  }, [maxFutureDays])

  const updateReason = (_, { value }) => setReason(value)

  const clearNoTimeFields = () => {
    setDesk('')
    setSelectedMarker('')
  }

  const resetTimes = (value = date) => {
    setEntryTime(getDefaultEntryTime(value))
    setExitTime(getDefaultExitTime())
    setAllTimeValue(false)
  }

  const updateDate = (_, { value }) => {
    setDate(value)    
    resetTimes(value)
    fetchFloorMap(floorMapId, value)
    clearNoTimeFields()
  }

  const updateEntryTime = (_, { value }) => {
    setEntryTime(value)
    clearNoTimeFields()
  }
  const updateExitTime = (_, { value }) => {
    setExitTime(value)
    clearNoTimeFields()
  }

  const onChangeSite = (_, { highlightedIndex }) => {
    autoSetSite(highlightedIndex, setPlaceMethods)
    setDesk('')
    setSelectedMarker('')
  }

  const onChangeBuilding = (_, { highlightedIndex }) => {
    autoSetBuilding(highlightedIndex, setPlaceMethods)
    setDesk('')
    setSelectedMarker('')
  }

  const onChangeFloor = (_, { highlightedIndex }) => {
    autoSetFloor(highlightedIndex, setPlaceMethods)
    setDesk('')
    setSelectedMarker('')
  }

  const onCancelMarker = useCallback(() => {
    const _markers = [...markers]

    _markers.forEach(m => {
      if (m.id === desk.id) {
        m.selected = true
      } else m.selected = false
    })

    setOpenMap(false)
    setMarkers(_markers)
    setSelectedMarker(desk)
  }, [desk, markers])

  const getLegend = useCallback(() => {
    return (
      <Flex
        direction="row"
        spacing={4}
        className="legend"
      >
        <label><DeskDot width="12" color="#5F75B9" /> {t('available')}</label>
        <label><DeskDot width="12" color="#8C8CA1" /> {t('busy')}</label>
        <label><DeskDot width="12" color="#75D385" /> {t('selected')}</label>
      </Flex>
    )
  }, [t])

  const saveReservation = () => {
    setLoading(true)


    const payload = {
      desk_reservation: {
        reservation_date: date,
        desk_id: desk.id,
        entry_time,
        exit_time,
        reason,
      }
    }

    if (allTimeValue) {
      const { start, end } = getAllTime()
      payload.desk_reservation.entry_time = start
      payload.desk_reservation.exit_time = end
    }

    api.post('/workplace/desk_reservation', payload)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success && data.message) {
          dispatch({
            type: Types.SET_ALERT_DATA,
            payload: {
              open: true,
              content: errorCreateReservationWorkplaceI18n(data.message),
              danger: true,
              autoHideDuration: 4000
            }
          })
        } else {
          history.push('/')
          dispatch({
            type: Types.SET_ALERT_DATA,
            payload: {
              open: true,
              content: t('success workplace reservation'),
              success: true,
              autoHideDuration: 4000
            }
          })
        }
      })
      .catch(error => defaultCatch(error, history))
  }

  const onClickMarker = useCallback((e, marker) => {
    const _markers = [...markers]
    if (marker.occupied) return

    _markers.forEach(m => {
      if (m.id === marker.id)
        m.selected = true
      else m.selected = false
    })

    setMarkers(_markers)
    setSelectedMarker(marker)

  }, [markers])

  const onConfirmMarker = useCallback(() => {
    setDesk(selectedMarker)
    setOpenMap(false)
  }, [selectedMarker])

  return (
    <NewReservationLayout
      title={t('New reservation: workplace')}
      onCancel={onCancel}>
      <div
        className="NewReservation"
      >
        <Form>
          <Flex gap="gap.small">
            <CalendarOutline className='input-icon' />
            <FormDatepicker
              selectedDate={date}
              onDateChange={updateDate}
              minDate={today}
              maxDate={getMaxDateWorkplace()}
              inputPlaceholder={t('date')}
              allowManualInput={false}
              inputOnly
            />
          </Flex>
          <Hide hide={!askForDeskReservationEntryTime}>
            <Flex gap="gap.medium" column>
              <Flex gap="gap.small">
                <Flex gap="gap.small" vAlign='center'>
                  <ClockOutline className='input-icon' />
                  <FormDropdown
                    items={HOURS}
                    placeholder={t('hour')}
                    disabled={allTimeValue || !date}
                    onChange={updateEntryTime}
                    value={entry_time}
                    inverted
                  />
                </Flex>
                <Checkbox
                  label={t('all day')}
                  checked={allTimeValue}
                  onClick={e => setAllTimeValue(!allTimeValue)}
                  toggle
                />
              </Flex>
              <Flex gap="gap.small">
                <ClockOutline className='input-icon' />
                <FormDropdown
                  items={HOURS}
                  placeholder={t('hour')}
                  disabled={allTimeValue || !date}
                  onChange={updateExitTime}
                  error={!!exitTimeError}
                  value={exit_time}
                  inverted
                />
              </Flex>
              {
                exitTimeError &&
                <FormMessage role="alert" error>
                  {exitTimeError}
                </FormMessage>
              }
            </Flex>
          </Hide>
          <Hide hide={sites.length === 1}>
            <Flex gap="gap.small">
              <MarkerOutline className='input-icon' />
              <FormDropdown
                disabled={!date}
                onChange={onChangeSite}
                placeholder={t('site')}
                items={getPlaces(sites)}
                value={getPlaceValue(sites, site)}
                inverted
              />
            </Flex>
          </Hide>
          <Hide hide={buildings.length === 1}>
            <Flex gap="gap.small">
              <Building className='input-icon' />
              <FormDropdown
                onChange={onChangeBuilding}
                placeholder={t('building')}
                disabled={!site}
                items={getPlaces(buildings)}
                value={getPlaceValue(buildings, building)}
                inverted
              />
            </Flex>
          </Hide>
          <Hide hide={floors.length === 1}>
            <Flex gap="gap.small">
              <Floor className='input-icon' />
              <FormDropdown
                onChange={onChangeFloor}
                placeholder={t('floor')}
                disabled={!building}
                items={getPlaces(floors)}
                value={getPlaceValue(floors, floor)}
                inverted
              />
            </Flex>
          </Hide>
          <Flex gap="gap.small">
            <Place className='input-icon' />
            <Dialog
              open={openMap}
              onOpen={() => setOpenMap(true)}
              onCancel={onCancelMarker}
              header={<>
                <Hide movil>
                  {getLegend()}
                </Hide>
                <Hide web>
                  <div className="detail-map-header">
                    <h1>{t('selected desk')}</h1>
                    <h2>{selectedMarker.name}</h2>
                  </div>
                </Hide>
              </>}
              headerAction={{
                icon: <CloseIcon />,
                title: 'Close',
                onClick: onCancelMarker,
              }}
              content={
                <FloorMap
                  floorImage={floorImage}
                  markers={markers}
                  onClickMarker={onClickMarker}
                />
              }
              footer={
                <>
                  <Hide web>
                    {getLegend()}
                  </Hide>
                  <Button
                    primary
                    disabled={selectedMarker.id === -1}
                    onClick={onConfirmMarker} >
                    {t('confirm')}
                  </Button>
                </>
              }
              trigger={
                <Input
                  disabled={!floorImage.url || !floor}
                  className='input-desk'
                  placeholder={t('desk')}
                  value={desk.name}
                  inverted
                />
              }
            />
          </Flex>
          <Hide hide={!askForDeskReservationReason}>                   
            <Flex gap="gap.small">
              <StickerOutline className='input-icon' />
              <FormDropdown
                items={reasons}
                placeholder={t('reason')}
                disabled={!entry_time}
                onChange={updateReason}
                value={reason}
                inverted
              />
            </Flex>
          </Hide>
          <Flex gap="gap.small" hAlign="end">
            <Button
              disabled={!isEnabled()}
              content={t('confirm reservation')}
              primary
              onClick={saveReservation}
            />
          </Flex>
        </Form>
      </div>
    </NewReservationLayout>
  )
}

const mapStateToProps = state => {
  return {
    askForDeskReservationReason: state.profile.company.ask_for_desk_reservation_reason,
    askForDeskReservationEntryTime: state.profile.company.ask_for_desk_reservation_entry_time,
    minReservationTime: state.profile.company.min_reservation_time,
    workplaceDefaultEntryTime: state.profile.company.workplace_default_entry_time,
    workplaceDefaultExitTime: state.profile.company.workplace_default_exit_time,
    sites: state.workplace.sites,
  }
}

const mapDispatchToProps = {
  dispatch,
  setLoading,
  defaultSuccess,
  defaultCatch
}


export default connect(mapStateToProps, mapDispatchToProps)(DeskReservation)
