import './Hide.css'

const Hide = props => {
  const {
    web = false,   //ONLY WEB
    movil = false, //ONLY MOVIL
    hide = false,  //ALWAYS HIDDEN
    children
  } = props;

  return (
    <div
      style={{ display: 'inline-flex', alignItems: 'center', marginTop: 0 }}
      className={`${web ? 'hide-web' : ''} ${movil ? 'hide-movil' : ''} ${hide ? 'hide' : ''}`}
    >
      {children}
    </div>
  )
}




export default Hide