import MobileDetect from 'mobile-detect'

import { DESK_RESERVATION, DISTANCE_TOLERANCE_KM_MOVIL, DISTANCE_TOLERANCE_KM_WEB, EIGHT_AM, ERROR, FIVE_PM, INITIAL_OFFSET, OK, PARKING_RESERVATION, REGEX_HTTP_URL, ROOM_RESERVATION, UNSUPPORTED } from '../../variables'
import {
  removeTimezoneFromISO,
  stringHourToJsDate
} from './FormatDate'

import {
  AirConditioner,
  Blackboard,
  Plug,
  Projector,
  VideoConference,
  Wifi,
  Monitor,
  ExpandableRoom,
  Phone
} from '../components/Svgs/Amenities'


const isParking = item => item.type === PARKING_RESERVATION 
const isWorkplace = item => item.type === DESK_RESERVATION  || item.type === ROOM_RESERVATION
const isDesk = item => item.type === DESK_RESERVATION
const isReservation = item => item.type === PARKING_RESERVATION || item.type === DESK_RESERVATION
const defaultFunction = () => { }

const getCurrentBrowser = () => {
  var ua = navigator.userAgent
  var tem
  var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return 'IE ' + (tem[1] || '')
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
  return M.join(' ');
}

const getPlace = item => {
  const {
    site = { name: '' },
    building = { name: '' },
    floor = { name: '' }
  } = item
  return `${site.name}, ${building.name}, ${floor.name}`
}

const sortItems = (items = []) => {
  return items.sort((a, b) => {
    const _a = a.name.toLowerCase()
    const _b = b.name.toLowerCase()

    if (_a < _b) return -1
    else if (_a > _b) return 1
    return 0
  })
}

const getPosition = (m, imageHeight) => {
  return [
    - m.y + imageHeight - INITIAL_OFFSET,
    m.x + INITIAL_OFFSET
  ]
}

const parseMessageHtml = message => {
  const regex = REGEX_HTTP_URL;
  return message.replace(regex, (match) => {
    return `<a href="${match}" target="_blank">${match}</a>`;
  })
}

const cleanLocale = (locale = '') => locale.split('-')[0]

const cleanReservation = (reservations = [], params = {}) => {
  const { type } = params
  const _reservations = reservations.filter(r => r.status !== 'RELEASED')
  return _reservations.map(r => ({
    ...r,
    type: type ? type : r.type,
    entry_time: removeTimezoneFromISO(r.entry_time),
    exit_time: removeTimezoneFromISO(r.exit_time)
  }))
}

const getAmenitieImage = (i, t) => {
  if (i === 0) return <label title={t("Airconditioner")}> <AirConditioner width={16} /></label>
  if (i === 1) return <label title={t("Blackboard")}> <Blackboard width={16} /></label>
  if (i === 2) return <label title={t("Plug")}> <Plug width={16} /></label>
  if (i === 3) return <label title={t("Projector")}> <Projector width={16} /></label>
  if (i === 4) return <label title={t("Videoconference")}> <VideoConference width={16} /></label>
  if (i === 5) return <label title={t("Wifi")}> <Wifi width={16} /></label>
  if (i === 6) return <label title={t("Monitor")}><Monitor width={16} /></label>
  if (i === 7) return <label title={t("Expandable room")}><ExpandableRoom width={20} /></label>
  if (i === 8) return <label title={t("Phone")}><Phone width={16} /></label>
}

const stopEvent = e => {
  if (e.preventDefault !== undefined) e.preventDefault()
  if (e.stopPropagation !== undefined) e.stopPropagation()
}

const getLocalitation = (reservation = {}) => {
  const site = reservation?.site?.name || ''
  const building = reservation.building?.name || ''
  const floor = reservation?.floor?.name || ''
  const items = [site, building, floor]
  return items.filter(i => i !== '').join(', ')
}

const getWorkplaceDefaultEntryTime = (state, toJsDate = false) => {
  let time =  state.profile.company.workplace_default_entry_time ?
    state.profile.company.workplace_default_entry_time :
    EIGHT_AM

  return toJsDate ? stringHourToJsDate(time) : time
}

const getWorkplaceDefaultExitTime = (state, toJsDate = false) => {
  let time =  state.profile.company.workplace_default_exit_time ?
    state.profile.company.workplace_default_exit_time :
    FIVE_PM

  return toJsDate ? stringHourToJsDate(time) : time
}

const autoSetPlace = (sites = [], {
  setSite = defaultFunction,
  setBuildings = defaultFunction,
  setBuilding = defaultFunction,
  setFloors = defaultFunction,
  setFloor = defaultFunction,
  setFloorMapId = defaultFunction
}) => {
  if (sites.length === 1) {
    const siteItem = sites[0]
    setSite(siteItem.id)
    setBuildings(siteItem.buildings)
 
    const buildingItem = siteItem.buildings[0]
    if (siteItem.buildings.length === 1) {
      setBuilding(buildingItem.id)
      setFloors(buildingItem.floors)
 
      const floorItem = buildingItem.floors[0]
      if (buildingItem.floors.length === 1) {
        setFloor(floorItem.id)
        setFloorMapId(floorItem.floor_map.id)
      }
    }
  }
}

const autoSetSite = (index, methods) => {
  const {
    sites = [],
    setSite = defaultFunction,
    setBuildings = defaultFunction,
    setBuilding = defaultFunction
  } = methods
  
  const site = sites[index].id;
  const buildings = sites.find(s => s.id === site).buildings || [];
  setSite(site)
  setBuildings(sortItems(buildings))
  setBuilding('')

  if (buildings.length === 1) {
    autoSetBuilding(0, methods)
  }
}

const autoSetBuilding = (index, methods) => {
  const {
    buildings = [],
    setBuilding = defaultFunction,
    setFloors = defaultFunction,
    setFloor = defaultFunction,
    setFloorMapId = defaultFunction
  } = methods

  if (!buildings.length) return
  const building = buildings[index].id;
  const floors = buildings.find(b => b.id === building).floors || [];
  setBuilding(building)
  setFloors(sortItems(floors))
  setFloor('')
  setFloorMapId('')

  if (floors.length === 1) {
    autoSetFloor(0, methods);
  }
}

const autoSetFloor = (index, methods) => {
  const { 
    floors = [],
    setFloor = defaultFunction,
    setFloorMapId = defaultFunction
  } = methods

  if (!floors.length) return
  const floor = floors[index].id
  setFloor(floor)
  setFloorMapId(floors[index].floor_map.id)
}


const blobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function (event) {
      const base64Image = event.target.result
      resolve(base64Image)
    }

    reader.onerror = function (error) {
      reject(error)
    }

    reader.readAsDataURL(blob)
  })
}

const getFullNameGuest = params => {
  const {
    first_name,
    last_name_one,
    last_name
  } = params

  return `${first_name} ${last_name_one ? last_name_one : last_name}`
}

const getPlaces = (places = []) => places.map(p => p.name)
const getPlaceValue = (places = [], place = {}) => (places.find(p => p.id === place) || {}).name || ''
  

function calculateDistanceKM(position1, position2) {  // Kilometers
  const earthRadius = 6371
  const {
    latitude: lat1,
    longitude: lon1
  } = position1

  const {
    latitude: lat2,
    longitude: lon2
  } = position2

  const latRad1 = (Math.PI / 180) * lat1
  const lonRad1 = (Math.PI / 180) * lon1
  const latRad2 = (Math.PI / 180) * lat2
  const lonRad2 = (Math.PI / 180) * lon2

  const latDiff = latRad2 - latRad1
  const lonDiff = lonRad2 - lonRad1

  const a =
    Math.sin(latDiff / 2) ** 2 +
    Math.cos(latRad1) *
    Math.cos(latRad2) *
    Math.sin(lonDiff / 2) ** 2
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = earthRadius * c

  // Distance in kilometers
  return distance
}

const isMobile = () => {
  const md = new MobileDetect(window.navigator.userAgent)
  return md.mobile()
}

const isNear = (position1, position2) => {
  const distance = calculateDistanceKM(position1, position2)
  const distanceTolerance = isMobile() ?
    DISTANCE_TOLERANCE_KM_MOVIL :
    DISTANCE_TOLERANCE_KM_WEB

  return distance <= distanceTolerance
}

async function getCurrentLocation() {
  let latitude = 0
  let longitude = 0

  if ('geolocation' in navigator) {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      return { status: OK, latitude, longitude }
    } catch (error) {
      const message = `Error while obtaining geolocation: ${error?.message}`
      return { status: ERROR, message, latitude, longitude }
    }
  } else {
    const message = "Geolocation is not available in this browser."
    return { status: UNSUPPORTED, message, latitude, longitude }
  }
}


export {
  isParking,
  isWorkplace,
  getCurrentLocation,
  isDesk,
  isReservation,
  autoSetSite,
  autoSetBuilding,
  autoSetFloor,
  getPlaces,
  getPlaceValue,
  stopEvent,
  getFullNameGuest,
  autoSetPlace,
  getWorkplaceDefaultEntryTime,
  getWorkplaceDefaultExitTime,
  getLocalitation,
  defaultFunction,
  getAmenitieImage,
  cleanReservation,
  parseMessageHtml,
  getCurrentBrowser,
  blobToBase64,
  getPosition,
  cleanLocale,
  getPlace,
  sortItems,
  isNear
}