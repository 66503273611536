import { Types } from '../actionTypes'

const initialState = {
  loading: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_BACKDROP_TOGGLE:
      return { loading: !state.loading }
    case Types.SET_BACKDROP_LOADING:
      const { loading = false } = action.payload
      return { loading }
    default:
      return state
  }
}

export default reducer