import React from 'react'
import { connect } from 'react-redux'

import {
  Loader
} from '@fluentui/react-northstar'

import { useTranslation } from 'react-i18next'

import './GlobalLoader.css'

function GlobalLoader({ loading }) {
  const { t } = useTranslation()

  if (!loading) return ''
  return (
    <div className="global-loader">
      <Loader
        label={`${t('loading')}...`}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.backdrop.loading
  }
}

export default connect(mapStateToProps)(GlobalLoader)
