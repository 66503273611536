import React from 'react'
import { CloseIcon, Dialog } from '@fluentui/react-northstar'
import { useTranslation } from 'react-i18next'

const AlertDialog = props => {
  const {
    open,
    handleClose,
    onClickLeftButton,
    onClickRightButton,
  } = props

  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onCancel={onClickLeftButton}
      onConfirm={onClickRightButton}
      content=""
      headerAction={{
        icon: <CloseIcon />,
        title: 'Close',
        onClick: handleClose,
      }}
      cancelButton={t('Per Hours')}
      confirmButton={t('All day')}
      header={t('Would you like to book the room all day?')}
    />

  )
}

export default AlertDialog