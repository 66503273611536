import axios from 'axios'
const DEFAULT_DOMAIN = 'public'

const clearDomain = domain => {
  const _domain = domain ||
    localStorage.getItem('domain') ||
    DEFAULT_DOMAIN

  if (
    _domain === 'undefined' ||
    _domain === DEFAULT_DOMAIN
  ) return ''
  return `${_domain}.`
}

const getBaseUrl = domain => {
  return process.env.NODE_ENV === 'development' ?
  `https://${clearDomain(domain)}corporatedev.parso.cr/api`:
  `https://${clearDomain(domain)}corporateparking.parso.cr/api`  
}

const api = axios.create()

api.interceptors.request.use(
  config => {
    config.headers['tenant'] = localStorage.getItem('domain') || 'public'

    let sessionTokens = localStorage.getItem('tokens')
    sessionTokens = JSON.parse(sessionTokens)
    if (sessionTokens['access-token']) {
      config.headers['access-token'] = sessionTokens['access-token']
      config.headers['client'] = sessionTokens['client']
      config.headers['uid'] = sessionTokens['uid']
    }
    config.baseURL = getBaseUrl()
    return config
  }
)

const doneHandler = response => {
  if (response?.headers['access-token']) {
    setTokens(response.headers)
  }

  return response;
}

const errorHandler = error => {
  return Promise.reject(error);
}

api.interceptors.response.use(doneHandler, errorHandler);


const setTokens = tokens => localStorage.setItem('tokens', JSON.stringify(tokens))

const clearAuth = () => {
  localStorage.setItem('tokens', '[]')
  localStorage.setItem('domain', '')
}

export {
  api,
  getBaseUrl,
  setTokens,
  clearAuth
}

