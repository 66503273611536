import { Types } from '../actionTypes'

const initialState = {
  sites: [],
  sitesRooms: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_SITES:
      const sites = action.payload
      return { ...state, sites }
    case Types.SET_SITES_ROOMS:
      const sitesRooms = action.payload
      return { ...state, sitesRooms }
    case Types.CLEAN_AUTH:
      return initialState
    default:
      return state
  }
}

export default reducer;