export const Types = {
  SET_DOMAIN: 'SET_DOMAIN',
  SET_AUTH: 'SET_AUTH',
  CLEAN_AUTH: 'CLEAN_AUTH',
  SET_PARKING_REASONS: 'SET_PARKING_REASONS',
  SET_PARKING_TOTAL_RESERVATIONS: 'SET_PARKING_TOTAL_RESERVATIONS',
  SET_WORKPLACE_REASONS: 'SET_WORKPLACE_REASONS',
  SET_WORKPLACE_TOTAL_RESERVATIONS: 'SET_WORKPLACE_TOTAL_RESERVATIONS',
  SET_ALERT_TOGGLE: 'SET_ALERT_TOGGLE',
  SET_ALERT_DATA: 'SET_ALERT_DATA',
  SET_BACKDROP_TOGGLE: 'SET_BACKDROP_TOGGLE',
  SET_BACKDROP_LOADING: 'SET_BACKDROP_LOADING',
  SET_IS_ONLINE: 'SET_IS_ONLINE',
  SET_TRUE_HAS_ACCEPTED_PRIVACY_POLICY: 'SET_TRUE_HAS_ACCEPTED_PRIVACY_POLICY',
  SET_SITES: 'SET_SITES',
  SET_SITES_ROOMS: 'SET_SITES_ROOMS'
}