import React from 'react'
import { connect } from 'react-redux'

import {
  Alert,
  PresenceAvailableIcon,
  ExclamationTriangleIcon
} from '@fluentui/react-northstar'

import './GlobalAlert.css'
import { useEffect } from 'react'
import { Types } from "../../state/actionTypes"


function GlobalAlert(props) {
  const {
    open,
    content,
    success,
    danger,
    autoHideDuration,
    dispatch
  } = props

  useEffect(() => {
    if (!open) return
    setTimeout(() =>
      dispatch({
        type: Types.SET_ALERT_DATA,
        payload: {
          open: false,
          content: '',
          success: false,
          danger: false,
          autoHideDuration: 0
        }
      }),
      autoHideDuration
    )
  }, [autoHideDuration, dispatch, open])

  if (!open) return ''
  return (
    <div className="global-alert">
      <Alert
        icon={
          success ?
            <PresenceAvailableIcon /> :
            <ExclamationTriangleIcon />
        }
        content={content}
        success={success}
        danger={danger}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    open: state.alert.open,
    content: state.alert.content,
    success: state.alert.success,
    danger: state.alert.danger,
    autoHideDuration: state.alert.autoHideDuration
  }
}

export default connect(mapStateToProps)(GlobalAlert)
