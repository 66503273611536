import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Dialog,
  Button,
  Flex,
  Image,
  Pill
} from '@fluentui/react-northstar'
import { useTranslation } from 'react-i18next'


import { api } from '../../api/api'
import {
  setLoading,
  defaultSuccess,
  defaultCatch,
} from '../../state/actions'

import { defaultFunction, getAmenitieImage, getLocalitation } from '../../common/utilities/utilities'

//import './RoomDetailsModal.sass'
import { NOT_AVAILABLE } from '../../variables'
import Hide from '../Hide/Hide'

const RoomDetailsModal = (props) => {
  const {
    room = {},
    open = false,
    handleClose = defaultFunction,
    setRoomDetailsModalOpen,
    onConfirmRoom,
    setLoading,
    defaultSuccess,
    defaultCatch
  } = props

  const { t } = useTranslation()
  const history = useHistory()
  const [roomImage, setRoomImage] = useState()

  const fetchRoomImage = () => {
    const image = room?.photos[room?.photos?.length - 1]?.url.replace(/^\/api/, '')
    if (!image) return setRoomImage()

    setLoading(true)
    api.get(image, { responseType: "blob" })
      .then(defaultSuccess)
      .then(({ data }) => setRoomImage(URL.createObjectURL(data)))
      .catch(error => defaultCatch(error, history))
  }

  useEffect(() => {
    if (open) fetchRoomImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const getAmenities = (amenities = []) => {
    return amenities.map(a =>
      <Pill
        key={a}
        appearance="outline"
        rectangular
        content={
          <Flex gap="gap.small" vAlign='center'>
            {getAmenitieImage(a, t)}
            <label>{t(getAmenitieImage(a, t).props.title)}</label>
          </Flex>
          }
        className='amenity-pill'
      />
    )
  }

  const handleConfirmationRoom = () => {
    onConfirmRoom()
    setRoomDetailsModalOpen(false)
  }

  return (
    <Dialog
      open={open}
      onCancel={handleClose}
      content={
        <Flex gap='gap.medium' column>
         <Flex column gap="gap.small">
            {roomImage && <Image src={roomImage} alt="room image" />}
            <Flex column gap="gap.small">
              <h2>{room?.name}</h2>
              <Flex column>
                <div>{`${t('Location')}:  ${getLocalitation(room)}`}</div>
                <div>{t('capacity')}: {room?.capacity} {t('seats')}</div>
              </Flex>
            </Flex>
            {room?.amenities?.length !== 0 &&
              <Flex column gap="gap.small">
                {`${t('amenities')}:`}
                <Flex wrap gap="gap.small">
                  {getAmenities(room?.amenities)}
                </Flex>
              </Flex>
            }
          </Flex>
          <Flex space='between'>
              <Button
                icon={<ArrowLeftIcon />}
                onClick={() => {
                  handleClose()
                  setRoomImage(undefined)
                }}
                content={t('back')}
              />
              <Hide hide={room.label === NOT_AVAILABLE}>
                <Button
                  primary
                  icon={<ArrowRightIcon />}
                  onClick={handleConfirmationRoom}
                  content={t('reserve room')}
                />
              </Hide>
            </Flex>
        </Flex>
      }
    />
  )
}

const mapDispatchToProps = {
  setLoading,
  defaultSuccess,
  defaultCatch
}

export default connect(null, mapDispatchToProps)(RoomDetailsModal)
