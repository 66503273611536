import React, { useCallback, useState } from 'react'
import { Button, CloseIcon, Dialog, Flex } from '@fluentui/react-northstar'
import { useTranslation } from 'react-i18next'

import TimeRangeSelector from '../TimeRangeSelector/TimeRangeSelector'
import { DeskDot } from '../Svgs/Svgs'
import { jsDateToStringHour } from '../../common/utilities/FormatDate'

import './ScheduleSelector.css'
import Hide from '../Hide/Hide'

const ScheduleSelector = props => {
  const {
    open,
    isToday = false,
    handleClose,
    prevReservations,
    isDateAvailable,
  } = props

  const { t } = useTranslation()
  const [hasCollision, setHasCollision] = useState(false)
  const [entryTime, setEntryTime] = useState()
  const [exitTime, setExitTime] = useState()

  const onChange = useCallback(params => {
    const { entry_time, exit_time, hasCollision } = params
    setHasCollision(hasCollision)

    if (props.onChange) {
      props.onChange(params)
      return
    }

    setEntryTime(entry_time)
    setExitTime(exit_time)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onContinue = () => {
    props.onContinue({
      entryTime: jsDateToStringHour(entryTime),
      exitTime: jsDateToStringHour(exitTime)
    })
  }


  return (
    <Dialog
      open={open}
      headerAction={{
        icon: <CloseIcon />,
        title: 'Close',
        onClick: handleClose,
      }}
      header={
        isDateAvailable ? 
          t('Select the time you would like to book') :
          t('View who booked the room')
      }
      content={
        <Flex column gap='gap.medium'>
          <div className='schedule-selector-legend'>
            <DeskDot />
            {t('Unavailable Hours')}
          </div>
          <TimeRangeSelector
            onChange={onChange}
            prevReservations={prevReservations}
            availableDate={isDateAvailable}
            entryTime={props.entryTime ? props.entryTime : entryTime}
            exitTime={props.exitTime ? props.exitTime : exitTime}
            isToday={isToday}
          />
           <Hide hide={!isDateAvailable}>
              <Button
                size='medium'
                fluid
                disabled={hasCollision}
                primary
                onClick={onContinue}
              >
                {t('save')}
              </Button>
           </Hide>
        </Flex>
      }
    />
  )
}

export default ScheduleSelector