// https://fluentsite.z22.web.core.windows.net/quick-start
import { HashRouter as Router, Redirect, Route } from 'react-router-dom'
import { Provider, teamsTheme } from '@fluentui/react-northstar'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from '../state/store'
import Privacy from './Privacy'
import TermsOfUse from './TermsOfUse'
import Tab from './Tab'

const { store, persistor } = configureStore()
/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  return (
    <Provider theme={teamsTheme}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Route exact path="/">
              <Redirect to="/tab" />
            </Route>
            <>
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/politica-privacidad" component={Privacy} />
              <Route exact path="/termsofuse" component={TermsOfUse} />
              <Route exact path="/terms-and-conditions" component={TermsOfUse} />
              <Route path="/tab" component={Tab} />
            </>
          </Router>
        </PersistGate>
      </ReduxProvider>
    </Provider>
  );
}
