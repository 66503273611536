import React, { useCallback, useEffect } from 'react'
import { connect } from "react-redux"

import * as L from 'leaflet'

import { setLoading } from '../../state/actions'
import './FloorMap.css'
import { CENTER, DEFAULT_ZOOM, MIN_ZOOM } from '../../variables'
import { getPosition } from '../../common/utilities/utilities'

let map = {}

const defaultFunction = () => { }


function FloorMap(props) {

  const {
    className,
    markers = [],
    floorImage,
    zoom = DEFAULT_ZOOM,
    center,
    minZoom = MIN_ZOOM,
    onClickMarker = defaultFunction,
    setLoading
  } = props

  const whenReady = useCallback(
    ({ target: map }) => {
      if (!floorImage) return
      const { url, size } = floorImage
      const _size = [...size]
      const bounds = [[0, 0], _size.reverse()]
      L.imageOverlay(url, bounds).addTo(map)
      if (center) map.setView(center, zoom)
      else {
        map.fitBounds(bounds)
        setTimeout(() => map.setZoom(zoom), 500)
      }
      setLoading(false)
    },
    [center, floorImage, setLoading, zoom]
  )

  const getIcon = m => {
    let iconUrl = 'blue_dot.svg'
    let shadowUrl = null

    if (m.selected) iconUrl = 'selected_dot.svg'
    else if (m.selection) {
      iconUrl = 'yellow_dot.svg'
      shadowUrl = 'yellow_dot_background.svg'
    }
    else if (m.occupied) iconUrl = 'gray_dot.svg'

    return L.icon({
      iconUrl,
      iconSize: [32, 32],
      iconAnchor: [16, 16],
      popupAnchor: null,
      shadowUrl: shadowUrl,
      shadowSize: [64, 64],
      shadowAnchor: [32, 32],
    })
  }

  const removeAllMarkers = () => {
    map.eachLayer(layer => {
      if (!layer._url) layer.remove()
    })
  }

  const createMap = () => {
    map = L.map('map', {
      attributionControl: false,
      crs: L.CRS.Simple,
      minZoom,
      center: CENTER,
      zoom: DEFAULT_ZOOM,
    })
    map.whenReady(whenReady)
  }

  const refreshMarkers = () => {
    if (
      !floorImage ||
      !markers
    ) return

    const imageHeight = floorImage.size[1]
    removeAllMarkers()
    markers.forEach(m => {

      const position = getPosition(m, imageHeight)

      L
        .marker(position, { icon: getIcon(m) })
        .on('click', e => onClickMarker(e, m))
        .addTo(map)
    })
  }

  useEffect(createMap, [minZoom, whenReady])

  useEffect(refreshMarkers, [floorImage, markers, onClickMarker])

  return (
    <div className={`FloorMap ${className}`}>
      <div id='map' />
    </div>
  )
}

const mapDispatchToProps = {
  setLoading
}

export default connect(null, mapDispatchToProps)(FloorMap)
