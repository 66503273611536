import { Types } from '../actionTypes'

const initialState = {
  open: false,
  content: '',
  success: false,
  danger: false,
  autoHideDuration: 0,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_ALERT_TOGGLE:
      return { open: !state.open }
    case Types.SET_ALERT_DATA:
      const { 
        open = false,
        content = '',
        success = false,
        danger = false,
        autoHideDuration = 0,
       } = action.payload

      return {         
        open,
        content,
        success,
        danger,
        autoHideDuration
      }
    default:
      return state
  }
}

export default reducer