import React, { useState, useEffect } from 'react'
import { AddIcon, Button, Flex, FormInput, SubtractIcon } from '@fluentui/react-northstar';
import './Counter.css'


const Counter = ({ initialValue = 1, min = 0, max = Infinity, onChange, isDisabled = false }) => {
  const [count, setCount] = useState(initialValue);

  useEffect(() => {
    setCount(initialValue);
  }, [initialValue]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value === '' || (/^\d+$/.test(value) && parseInt(value) >= min && parseInt(value) <= max)) {
      setCount(value);
      if (onChange) {
        onChange(parseInt(value));
      }
    }
  };

  const handleBlur = () => {
    if (count === '') {
      setCount(min);
      if (onChange) {
        onChange(min);
      }
    }
  };

  const handleIncrement = () => {
    setCount((prevCount) => {
      const newValue = Math.min(max, prevCount + 1);
      if (onChange) {
        onChange(newValue);
      }
      return newValue;
    });
  };

  const handleDecrement = () => {
    setCount((prevCount) => {
      const newValue = Math.max(min, prevCount - 1);
      if (onChange) {
        onChange(newValue);
      }
      return newValue;
    });
  };

  return (
    <Flex className='Counter' gap='gap.small'>
      <Button
        onClick={handleDecrement}
        primary
        disabled={count <= min || isDisabled}
        iconOnly
      >
        <SubtractIcon />
      </Button>
      <FormInput            
        onChange={handleInputChange}
        onBlur={handleBlur}
        value={count}
        fluid
      />
      <Button
        onClick={handleIncrement}
        primary
        disabled={count >= max || isDisabled}
        iconOnly
      >
        <AddIcon/>
      </Button>
    </Flex>
  );
};

export default Counter;
