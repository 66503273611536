import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import thunk from 'redux-thunk'

import backdrop from './reducers/backdrop'
import alert from './reducers/alert'
import profile from './reducers/profile'
import workplace from './reducers/workplace'

const rootReducer = combineReducers({
  workplace,
  backdrop,
  alert,
  profile
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['profile', 'workplace']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configureStore = () => {
  let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))
  let persistor = persistStore(store)
  return { store, persistor }
};

export default configureStore