import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Button,
  Flex,
  Input,
  FilterIcon,
  SearchIcon,
  InfoIcon,
} from '@fluentui/react-northstar'

import RoomCard from './RoomCard'
import { useTranslation } from 'react-i18next'

import './RoomsModal.css'
import { defaultFunction } from '../../common/utilities/utilities'
import { isTodayFromJsDate, today } from '../../common/utilities/FormatDate'
import { AFTERNOON, ALL_AFTERNOON, ALL_DAY, ALL_MORNING, ALLDAY, DEFAULT_ROOM_FILTERS, MORNING, NOT_AVAILABLE, PARTIAL_AFTERNOON, PARTIAL_MORNING } from '../../variables'
import AlertDialog from '../AlertDialog/AlertDialog'
import AddFiltersModal from '../AddFiltersModal/AddFiltersModal'
import ScheduleSelector from '../ScheduleSelector/ScheduleSelector'
import RoomDetailsModal from './RoomDetailsModal'
import { connect } from 'react-redux'



function RoomsModal(props) {

    const {
    onCancelRoom = defaultFunction,
    onClickRoom = defaultFunction,
    onContinueRoom = defaultFunction,
    rooms = {},
    date = today,
    defaultEntryTime,
    defaultExitTime,
    isLoading = false,
    defaultRoom
  } = props

  const { t } = useTranslation()

    const timeAvailability = useMemo(() => {
    return ({
      morning: { start: defaultEntryTime, end: '12:00' },
      afternoon: { start: '12:01', end: defaultExitTime },
      allday: { start: defaultEntryTime, end: defaultExitTime },
    })
  }, [defaultEntryTime, defaultExitTime])


  const [filteredRooms, setFilteredRooms] = useState(rooms)
  const [filter, setFilter] = useState(DEFAULT_ROOM_FILTERS)
  const [addFiltersModalOpen, setAddFiltersModalOpen] = useState(false)
  const [selectedRoom, setSelectedRoom] = useState(defaultRoom)
  const [activeFilter, setActiveFilter] = useState(MORNING)
  const [roomDetailsModalOpen, setRoomDetailsModalOpen] = useState(false)
  const [openScheduleSelector, setOpenScheduleSelector] = useState(false)
  const [isDateAvailable, setIsDateAvailable] = useState(true)
  const [openAlert, setOpenAlert] = useState(false)
  const [search, setSearch] = useState('')

  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number)
    return hours * 60 + minutes;
  }

  const onConfirmRoom = useCallback(() => {
    if (selectedRoom.label === ALL_DAY && !isTodayFromJsDate(date)) setOpenAlert(true)
    else setOpenScheduleSelector(true)
  }, [date, selectedRoom])

  const handleFilterRoomsByTime = useCallback(filter => {
    setSelectedRoom(defaultRoom)
    setActiveFilter(filter)
  }, [setSelectedRoom, defaultRoom])
  
  const checkRoomAvailability = useCallback((availability, timeSlot) => {
    if (timeSlot === timeAvailability.allday) {
      return availability?.some(slot =>
        timeToMinutes(slot[0]) <= timeToMinutes(timeSlot.start) &&
        timeToMinutes(slot[1]) >= timeToMinutes(timeSlot.end)
      )
    }

    return availability?.some(slot =>
      timeToMinutes(slot[0]) < timeToMinutes(timeSlot.end) &&
      timeToMinutes(slot[1]) > timeToMinutes(timeSlot.start)
    )
  }, [timeAvailability])

 const showNoRoomsAvailableMsg = useCallback(() => {
    if (!isLoading) {
      return (
        <div className='no-rooms-available'>
          <InfoIcon outline size="larger" />
          <h2>{t("Sorry, there is no data available according to the filters applied. Select other parameters to query.")}</h2>
        </div>
      )
    }
  }, [isLoading, t])

   const checkPartialAvailability = useCallback((availability, timePeriod) => {
    const periodStart = timeToMinutes(timePeriod.start);
    const periodEnd = timeToMinutes(timePeriod.end);
    const periodDuration = periodEnd - periodStart;

    return availability.some(([start, end]) => {
      const correctedStart = start < timePeriod.start ? timePeriod.start : start
      const correctedEnd = end > timePeriod.end ? timePeriod.end : end

      const slotStart = timeToMinutes(correctedStart)
      const slotEnd = timeToMinutes(correctedEnd)
      const slotDuration = slotEnd - slotStart

      const isPartiallyAvailable = slotStart < periodEnd && slotEnd > periodStart && slotDuration < periodDuration
      return isPartiallyAvailable
    })
  }, [])

    const determineStatus = useCallback(room => {
    let status = { message: t('Not Available'), className: 'not-available', label: NOT_AVAILABLE }

    const isAllDayAvailable = checkRoomAvailability(room.availability, timeAvailability.allday)
    if (isAllDayAvailable) status = { message: t('Available all day'), className: '', label: ALL_DAY }

    else if (activeFilter === MORNING) {
      const isMorningAvailable = checkRoomAvailability(room.availability, timeAvailability.morning)
      const isPartiallyMorningAvailable = checkPartialAvailability(room.availability, timeAvailability.morning)

      if (isPartiallyMorningAvailable) status = { message: t('Available some hours'), className: 'partially-available', label: PARTIAL_MORNING }
      else if (isMorningAvailable) status = { message: t('Available all morning'), className: '', label: ALL_MORNING }
    }

    else if (activeFilter === AFTERNOON) {
      const isAfternoonAvailable = checkRoomAvailability(room.availability, timeAvailability.afternoon)
      const isPartiallyAfternoonAvailable = checkPartialAvailability(room.availability, timeAvailability.afternoon)

      if (isPartiallyAfternoonAvailable) status = { message: t('Available some hours'), className: 'partially-available', label: PARTIAL_AFTERNOON }
      else if (isAfternoonAvailable) status = { message: t('Available all afternoon'), className: '', label: ALL_AFTERNOON }
    }

    return status

  }, [activeFilter, checkPartialAvailability, checkRoomAvailability, t, timeAvailability])


  const handleOpenRoomDetails = () => {
    setRoomDetailsModalOpen(true)
  }

  const handleSeeWhoBooked = () => {
    setOpenScheduleSelector(true)
    setIsDateAvailable(false)
  }

  const handleSelectCustomTime = useCallback(() => {
    setOpenAlert(false)
    setRoomDetailsModalOpen(false)
    setOpenScheduleSelector(true)
  }, [])

  const handleSelectAllTime = useCallback(() => {
    setOpenAlert(false)
    setRoomDetailsModalOpen(false)
    onContinueRoom({ allTime: true, selectedRoom })
  }, [onContinueRoom, selectedRoom])

    const handleCloseScheduleSelector = useCallback(() => {
    setOpenScheduleSelector(false)
    if (!isDateAvailable) setIsDateAvailable(true)
  }, [isDateAvailable])

  const handleBackScheduleSelector = useCallback(() => {
    setOpenScheduleSelector(false)
    if (!isDateAvailable) setIsDateAvailable(true)
  }, [isDateAvailable])

  const onContinueScheduleSelector = useCallback(params => {
    setOpenScheduleSelector(false)
    onContinueRoom({ ...params, selectedRoom })
  }, [onContinueRoom, selectedRoom])

  const handleCloseRoomDetails = () => {
    setRoomDetailsModalOpen(false)
  }

  const enabledToContinue = useCallback(() => {
    return (
      selectedRoom.id !== -1 &&
      selectedRoom.label !== NOT_AVAILABLE
    )
  }, [selectedRoom])

  const filterRooms = useCallback((rooms = []) => {
    // Filter text search
    if (search) {
      const _search = search.toLocaleLowerCase()
      rooms = rooms.filter(item =>
        item.name.toLowerCase().includes(_search)
      )
    }

    // Filter by Site, Floor, Building, Capacity and Amenities
    if (filter.filtered) {
      rooms = rooms?.filter(room => {
        const matchesSite = filter.site === -1 || room.site?.id === filter.site
        const matchesBuilding = filter.building === -1 || room.building?.id === filter.building
        const matchesFloor = filter.floor === -1 || room.floor?.id === filter.floor
        const matchesCapacity = !filter.capacity || room.capacity >= filter.capacity
        const matchesAmenities = filter.amenities?.length === 0 || filter.amenities?.every(amenity => room.amenities.includes(amenity))

        return matchesSite && matchesBuilding && matchesFloor && matchesCapacity && matchesAmenities
      })
    }

    return rooms
  }, [filter, search])

  useEffect(() => {
    setFilteredRooms(filterRooms(rooms))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, rooms, filter])

  const handleApplyFilters = params => {
    setFilter(params)
    setSelectedRoom(defaultRoom)
    setAddFiltersModalOpen(false)
  }
  
  return (
    <div className='RoomsModal'>
      <Flex column gap="gap.medium">
        <Flex space="between">
          <Input
            icon={<SearchIcon />}
            placeholder={t('search room')}
            onChange={event => setSearch(event.target.value)}
            iconPosition="end"
            fluid
          />
          <Button
            className='add-filters'
            icon={<FilterIcon />}
            text
            onClick={() => setAddFiltersModalOpen(true)}
            content="Agregar filtro"
          />
        </Flex>

        <label>Sala disponible en </label>
        <Flex gap="gap.small">
          <Button
            primary={activeFilter === MORNING}
            size="small"
            onClick={() => handleFilterRoomsByTime(MORNING)}
            content="Mañana"
          />
          <Button
            primary={activeFilter === AFTERNOON}
            size="small"
            onClick={() => handleFilterRoomsByTime(AFTERNOON)}
            content="Tarde"
          />
          <Button
            primary={activeFilter === ALLDAY}
            size="small"
            onClick={() => handleFilterRoomsByTime(ALLDAY)}
            content="Todo el día"
          />
        </Flex>
        <div className='room-list' >
          {filteredRooms.length === 0 ?
            showNoRoomsAvailableMsg() :
            <Flex
              column
              gap='gap.medium'
            >
              {filteredRooms.map(room => {
                const status = determineStatus(room)
                return (
                  <RoomCard
                    key={room.id}
                    status={status}
                    handleOpenRoomDetails={handleOpenRoomDetails}
                    handleSeeWhoBooked={handleSeeWhoBooked}
                    setSelectedRoom={setSelectedRoom}
                    selectedRoom={selectedRoom}
                    onClickRoom={onClickRoom}
                    room={room}
                  />
                )
              }
              )}
            </Flex>
          }
        </div>
        <Flex gap="gap.medium" hAlign='end'>
          <Button onClick={onCancelRoom}>
            {t('cancel')}
          </Button>
          <Button
            primary
            onClick={onConfirmRoom}
            disabled={!enabledToContinue()}
          >
            {t('continue')}
          </Button>
        </Flex>
      </Flex>
      <AddFiltersModal
        handleClose={() => setAddFiltersModalOpen(false)}
        handleApplyFilters={handleApplyFilters}
        open={addFiltersModalOpen}
        rooms={rooms}
      />
      <RoomDetailsModal
        setRoomDetailsModalOpen={setRoomDetailsModalOpen}
        handleClose={handleCloseRoomDetails}
        onConfirmRoom={onConfirmRoom}
        open={roomDetailsModalOpen}
        room={selectedRoom}
      />
      <AlertDialog
        onClickLeftButton={handleSelectCustomTime}
        onClickRightButton={handleSelectAllTime}
        handleClose={() => setOpenAlert(false)}
        open={openAlert}
      />
      <ScheduleSelector
        handleClose={handleCloseScheduleSelector}
        onContinue={onContinueScheduleSelector}
        handleBack={handleBackScheduleSelector}
        isToday={isTodayFromJsDate(date)}
        prevReservations={selectedRoom?.usage}
        isDateAvailable={isDateAvailable}
        open={openScheduleSelector}
      />
    </div>
  )
}



const mapStateToProps = state => {
  return {
    isLoading: state.backdrop.loading,
  }
}

export default connect(mapStateToProps)(RoomsModal)
