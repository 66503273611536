import React from 'react'
import { Flex, Button, CloseIcon } from '@fluentui/react-northstar'

import './NewReservationLayout.css'

function NewReservationLayout(props) {
  const {
    children,
    onCancel,
    title
  } = props

  return (
    <div className="NewReservationLayout">
      <Flex
        className='header-flex'
        vAlign='center'
        gap="gap.small"
      >
        <Button
          primary
          icon={<CloseIcon />}
          iconOnly
          text
          onClick={onCancel}
        />
        <h1>{title}</h1>
      </Flex>
      <div className='bodier'>
        {children}
      </div>
    </div>
  )
}


export default NewReservationLayout
