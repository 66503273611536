import { DateTime } from "luxon"

const formatAMPM = (date, format = 'string') => {
  if (!(date instanceof Date) || isNaN(date)) return ''
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let amPm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  if (format === 'array') return [hours, minutes, amPm]
  return hours + ':' + minutes + ' ' + amPm
}
const removeTimezoneFromISO = time => time.slice(0, -6)
const removeDateFromDateTimeISO = time => time.split('T')[1]
const clearTimeZone = time => removeDateFromDateTimeISO(removeTimezoneFromISO(time))
const formatDateObj = date => DateTime.fromISO(date)  // DateTime obj
const formatDateToJS = date => DateTime.fromISO(date).toJSDate()  // Js Date 
const formatDateMed = date => DateTime.fromISO(date).toLocaleString(DateTime.DATE_HUGE) // Friday, October 14, 1983
const formatDateISOShort = date => DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT) //13/05/2022

const formatDate = date => DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT)
const formatDateToMed = date => DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED) // Oct 14, 1983
const formatDateToISOShort = date => DateTime.fromJSDate(date).toISODate() // '1982-05-25'
const formatDateToISO = date => removeTimezoneFromISO(DateTime.fromJSDate(date).toISO()) // '2017-04-22T20:47:05.335'

const formatTime = time => formatAMPM(DateTime.fromISO(time).toJSDate()) // 11:55 am
const formatTimeFromJS = time => formatAMPM(time) // 11:55 am
const formatTimeFromJSArray = time => formatAMPM(time, 'array') // [11, 55, "am"]
const formatDateToTimeISO = time => removeDateFromDateTimeISO(removeTimezoneFromISO(DateTime.fromJSDate(time).toISO())) // '20:47:05.335'
const formatDateToTimeHour = time => DateTime.fromJSDate(time).hour // '20'

const getAllTime = () => {
  const start = clearTimeZone(DateTime.local().set({ hour: 0, minute: 0, second: 0 }).toISO())
  const end = clearTimeZone(DateTime.local().set({ hour: 23, minute: 59, second: 59 }).toISO())
  return { start, end }
}

const hasSameDayFromJs = (date, date2) => {
  const _date  = DateTime.fromJSDate(date)
  const _date2 = DateTime.fromJSDate(date2)
  return _date.hasSame(_date2, 'day')
}

const today = new Date()

const isToday = date => {
  const today = DateTime.local();
  const _date = DateTime.fromJSDate(date);
  return today.hasSame(_date, 'day');
}

const isTodayFromIso = date => DateTime.fromISO(date).hasSame(DateTime.local(), "day") // true or false

const getCurrentHour = ({plusHour = 0, plusMinutes = 0, toJSDate = false} = {}) => {
  const date = DateTime.now().plus({
    hour: plusHour,
    minutes: plusMinutes,
  })

  if (toJSDate) return date.toJSDate()
  const { hour, minute } = date.toObject()
  return `${hour < 10 ?  `0${hour}` : hour}:${minute}` 
}

const isAllDay = ({ entry_time, exit_time }) => {
  const _entry_time = formatDateToJS(entry_time)
  const _exit_time = formatDateToJS(exit_time)

  let entry_hour = _entry_time.getHours()
  let entry_minute = _entry_time.getMinutes()
  let exit_hour = _exit_time.getHours()
  let exit_minute = _exit_time.getMinutes()

  return (
    entry_hour === 0 &&
    entry_minute === 0 &&
    exit_hour === 23 &&
    exit_minute === 59
  )
}

const isTodayFromJsDate = date => {
  const today = DateTime.local();
  const _date = DateTime.fromJSDate(date);
  return today.hasSame(_date, 'day');
} // true or false

const getExactHour = (date, fromIso = false) => {  
  const dateObj =
    fromIso ?
      new Date(removeTimezoneFromISO(date)) :
      date

  const hours = dateObj.getHours()
  const minutes = dateObj.getMinutes()
  const seconds = dateObj.getSeconds()
  const totalTime = hours + minutes / 60 + seconds / 3600
  return totalTime.toFixed(2)
}

const isWithinTimeDifference = (time1, time2, difference) => {
  const entryTime = new Date(time1)
  const exitTime = new Date(time2)
  const diffInMinutes = Math.abs(exitTime - entryTime) / 60000
  return diffInMinutes < difference
}

const entryTimeIsLaterEqualThanExitTime = (entry_time, exit_time) => {
  if (!entry_time || !exit_time) return false
  const entry = formatDateToTimeISO(entry_time)
  const exit = formatDateToTimeISO(exit_time)
  return (entry >= exit)
}

const makeHour = ({ hour = 0, minute = 0, second = 0 } = {}) => {
  return DateTime.local().set({ hour, minute, second }).toJSDate()
}

const getNextRoundedTime = date => {
  let hour = date.getHours()
  let minute = date.getMinutes()

  if (minute === 0) minute = 0
  else if (minute <= 15) minute = 15
  else if (minute <= 30) minute = 30
  else if (minute <= 45) minute = 45
  else {
    hour = hour + 1
    minute = 0
  }

  return DateTime.local().set({ hour, minute, second: 0 }).toJSDate() // Js Date 
}

const getTime = item => {
  const {
    entry_time = '',
    exit_time = ''
  } = item
  const _entry_time = formatTime(removeTimezoneFromISO(entry_time))
  const _exit_time = formatTime(removeTimezoneFromISO(exit_time))
  return `${_entry_time} - ${_exit_time}`
}

const decomposeDecimalTime = (decimalTime, scale = 60) => {
  const _hour = decimalTime / scale
  const hour = Math.floor(_hour)
  const minuteDecimal = (_hour - hour) * scale
  const minute = Math.floor(minuteDecimal)
  const second = Math.floor((minuteDecimal - minute) * scale)
  return { hour, minute, second }
}

const stringHourToJsDate = stringHour => {
  if (!stringHour) return today
  const [hour, minute] = stringHour.split(':')
  const _hour = { hour: Number(hour), minute: Number(minute), second: 0 }
  return DateTime.local().set(_hour).toJSDate() // Js Date 
}

const jsDateToStringHour = fecha => {
  const hours = fecha.getHours().toString().padStart(2, '0');
  const minutes = fecha.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

export {
  today,
  getTime,
  decomposeDecimalTime,
  getNextRoundedTime,
  makeHour,
  isAllDay,
  getExactHour,
  isTodayFromIso,
  isTodayFromJsDate,
  isWithinTimeDifference,
  stringHourToJsDate,
  jsDateToStringHour,
  entryTimeIsLaterEqualThanExitTime,
  getAllTime,
  getCurrentHour,
  formatDateObj,
  formatDateMed,
  formatDateToJS,
  formatDateISOShort,
  formatDate,
  formatDateToMed,
  formatDateToISOShort,
  formatDateToISO,
  formatTime,
  formatTimeFromJS,
  hasSameDayFromJs,
  isToday,
  formatDateToTimeHour,
  formatTimeFromJSArray,
  formatDateToTimeISO,
  removeTimezoneFromISO,
  removeDateFromDateTimeISO
}