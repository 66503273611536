const REGEX_HTTP_URL = /(https?:\/\/[^\s]+)/g
const REGEX_ENDLINE = /\n/g
// eslint-disable-next-line no-useless-escape
const REGEX_EMAIL = /[a-zA-Z0-9\-]+[\._]?[a-zA-Z0-9]+[@][\w\-]+[.]\w{2,3}/

const DISTANCE_TOLERANCE_KM_MOVIL = 0.5//KM
const DISTANCE_TOLERANCE_KM_WEB = 15//KM 

const ONE_DAY = 24
const ONE_HOUR = 60
const ONE_MINUTE = ONE_HOUR / 60
const ONE_DAY_BY_HOUR = ONE_HOUR * ONE_DAY
const ONE_DAY_BY_HOUR_AVAILABLE = ONE_DAY_BY_HOUR - ONE_MINUTE
const FIFTEEN_MINUTES = ONE_HOUR / 4

const PENDING = 'PENDING'
const APPROVED = 'APPROVED'
const REJECTED = 'REJECTED'
const ABSENCE = 'ABSENCE'
const DISABLED = 'DISABLED'
const ENABLED = 'ENABLED'
const RELEASED = 'RELEASED'

const WARNING = 'WARNING'
const INFO = 'INFO'
const ERROR = 'ERROR'
const UNSUPPORTED = 'UNSUPPORTED'
const OK = 'OK'

const DEFAULT_OFFSET_MIN_RESERVATION_MINUTES = 5 // MINIMUM OFFSET TO CREATE A RESERVE
const DEFAULT_MIN_RANGE_RESERVATION_MINUTES = 30 // MINIMUM PERIOD OF DURATION OF A RESERVATION (between entry and exit) in MINUTES
const DEFAULT_FLOOR_IMAGE = { url: 'static_map.png', size: [] }
const IMAGE_BOUNDS = [[0, 0], [1000, 1000]]
const MIN_ZOOM = -3
const DEFAULT_ZOOM = -2
const INITIAL_OFFSET = 4
const CENTER = [0, 0]
const ONE_AM = '01:00'
const EIGHT_AM = '08:00'
const FIVE_PM = '17:00'
const END_DAY = '23:59'
const ELEVEN_PM = '23:00'

const PARKING_DESK_RESERVATION = 'ParkingDeskReservation'
const PARKING_ROOM_RESERVATION = 'ParkingRoomReservation'
const PARKING_RESERVATION = 'ParkingReservation'
const DESK_RESERVATION = 'Workplace::DeskReservation'
const ROOM_RESERVATION = 'Workplace::RoomReservation'

const ENABLED_PD = ['demo', 'astrazeneca', 'ibk', 'ibk2', 'public'] // Tenants with parking + desk 
const ENABLED_PR = ['demo', 'ibk', 'ibk2', 'public'] // Tenants with parking + rooms

const HOURS = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
]

const HOURS_AMPM = [
  '12 AM',
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
  '11 PM'
]

const NOT_AVAILABLE = 'NOT_AVAILABLE'
const ALL_DAY = 'ALL_DAY'
const ALL_MORNING = 'ALL_MORNING'
const PARTIAL_MORNING = 'PARTIAL_MORNING'
const ALL_AFTERNOON = 'ALL_AFTERNOON'
const PARTIAL_AFTERNOON = 'PARTIAL_AFTERNOON'
const MORNING = 'MORNING'
const AFTERNOON = 'AFTERNOON'
const ALLDAY = 'ALLDAY'

const DEFAULT_ROOM_FILTERS = {
  site: -1,
  building: -1,
  floor: -1,
  capacity: 1,
  amenities: [],
  filtered: false
}

export {
  ENABLED_PD,
  ENABLED_PR,
  ONE_DAY,
  ONE_HOUR,
  ONE_MINUTE,
  ONE_DAY_BY_HOUR,
  ONE_DAY_BY_HOUR_AVAILABLE,
  FIFTEEN_MINUTES,
  DEFAULT_MIN_RANGE_RESERVATION_MINUTES,
  DEFAULT_OFFSET_MIN_RESERVATION_MINUTES,
  PARKING_DESK_RESERVATION,
  PARKING_ROOM_RESERVATION,
  PARKING_RESERVATION,
  DEFAULT_FLOOR_IMAGE,
  NOT_AVAILABLE,
  ELEVEN_PM,
  ONE_AM,
  ALL_DAY,
  ALL_MORNING,
  PARTIAL_MORNING,
  ALL_AFTERNOON,
  PARTIAL_AFTERNOON,
  MORNING,
  AFTERNOON,
  ALLDAY,
  DEFAULT_ROOM_FILTERS,
  DESK_RESERVATION,
  ROOM_RESERVATION,
  REGEX_HTTP_URL,
  INITIAL_OFFSET,
  REGEX_ENDLINE,
  DEFAULT_ZOOM,
  IMAGE_BOUNDS,
  REGEX_EMAIL,
  MIN_ZOOM,
  EIGHT_AM,
  END_DAY,
  FIVE_PM,
  CENTER,
  HOURS,
  HOURS_AMPM,
  PENDING,
  APPROVED,
  REJECTED,
  ABSENCE,
  DISABLED,
  ENABLED,
  RELEASED,
  WARNING,
  INFO,
  ERROR,
  UNSUPPORTED,
  OK,
  DISTANCE_TOLERANCE_KM_MOVIL,
  DISTANCE_TOLERANCE_KM_WEB
}