import React from "react";

const MarkerOutline = ({ className = "" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 16">
      <path
        fill="#212121"
        d="M10 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM9 7a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm2.95 4.96a7 7 0 1 0-9.9 0l1.52 1.5 2.04 1.98.14.12a2 2 0 0 0 2.64-.12l2.43-2.37 1.13-1.12Zm-9.2-9.2a6 6 0 0 1 8.67 8.3l-.18.19-1.32 1.3-2.23 2.17-.09.08a1 1 0 0 1-1.2 0l-.1-.08-2.97-2.9-.58-.57-.17-.18a6 6 0 0 1 .17-8.31Z"
      />
    </svg>
  )
}

const ClockOutline = ({ className = "" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16">
      <path
        fill="#212121"
        d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0Zm0 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1Zm-.5 2a.5.5 0 0 1 .5.41V8h2.5a.5.5 0 0 1 .09 1H7.5a.5.5 0 0 1-.5-.41V3.5c0-.28.22-.5.5-.5Z"
      />
    </svg>
  )
}

const StickerOutline = ({ className = "" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14">
      <path
        fill="#212121"
        d="M11 0a3 3 0 0 1 3 2.82v4.56a2 2 0 0 1-.47 1.28l-.12.13-4.62 4.62a2 2 0 0 1-1.24.58l-.17.01H3a3 3 0 0 1-3-2.82V3a3 3 0 0 1 2.82-3H11Zm0 1H3a2 2 0 0 0-2 1.85V11a2 2 0 0 0 1.85 2H7v-3a3 3 0 0 1 2.82-3H13V3a2 2 0 0 0-1.85-2H11Zm1.78 7H10a2 2 0 0 0-2 1.85V12.78l.09-.07 4.62-4.62a1 1 0 0 0 .07-.09Z" />
    </svg>
  )
}
const CarOutline = ({ className = "" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 15"
    >
      <path
        fill="#212121"
        d="M11.9 0c1.2 0 2.2.8 2.4 2l.2 1h.8c.3 0 .6.3.7.6v.1c0 .4-.3.7-.6.8H14.8l.1.6c.6.1 1.1.7 1.1 1.4v5c0 .8-.7 1.5-1.5 1.5H14v1a1 1 0 1 1-2 0v-1H4v1a1 1 0 1 1-2 0v-1h-.5C.7 13 0 12.3 0 11.5v-5c0-.7.5-1.3 1-1.4l.2-.6H.8A.8.8 0 0 1 .8 3h.7l.2-1C1.9.8 2.9 0 4 0H12Zm2.6 6h-13c-.3 0-.5.2-.5.5v5c0 .3.2.5.5.5h13c.3 0 .5-.2.5-.5v-5c0-.3-.2-.5-.5-.5ZM4 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm8 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm-.1-7H4c-.7 0-1.3.5-1.4 1.2L2 5H14l-.6-2.8C13.2 1.5 12.6 1 12 1Z" />
    </svg>
  )
}

const Car = ({ className = "" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path
        fill="#6C85D3"
        d="M12.2.8c-.2-.5-.6-.8-1-.8H2.8c-.5 0-1 .3-1 .8L.2 5V11a1.1 1.1 0 1 0 2.2 0v-.4h9v.4c0 .6.5 1.1 1.1 1.1.6 0 1.2-.5 1.2-1.1V5.5l-.1-.5L12.2.8ZM2.9 8.2a1.1 1.1 0 1 1 0-2.2 1.1 1.1 0 0 1 0 2.3Zm8.2 0a1.1 1.1 0 1 1 0-2.2 1.1 1.1 0 0 1 0 2.3ZM1.8 4.5l.9-2.9c.1-.3.4-.5.7-.5h7.2c.3 0 .6.2.7.5l1 2.9H1.7Z"
      />
    </svg>
  )
}

const Chair = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.6925 3.05753C2.1075 2.47253 2.1075 1.52003 2.6925 0.935027C3.2775 0.350027 4.23 0.350027 4.815 0.935027C5.4 1.52003 5.4 2.47253 4.815 3.05753C4.2225 3.65003 3.2775 3.65003 2.6925 3.05753ZM1.5 11V4.25003H0V11C0 13.07 1.68 14.75 3.75 14.75H8.25V13.25H3.75C2.505 13.25 1.5 12.245 1.5 11ZM12 14.0525L8.1975 10.25H5.625V7.49003C6.675 8.35253 8.325 9.11003 9.75 9.11003V7.49003C8.505 7.50503 7.0425 6.83753 6.2475 5.96003L5.1975 4.79753C5.055 4.64003 4.875 4.51253 4.68 4.42253C4.4625 4.31753 4.215 4.25003 3.96 4.25003H3.9375C3.0075 4.25003 2.25 5.00753 2.25 5.93753V10.25C2.25 11.495 3.255 12.5 4.5 12.5H8.3025L10.9275 15.125L12 14.0525Z"
        fill="#6C85D3"
      />
    </svg>
  )
}

const Plus = ({ className = "" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 9 9"
    >
      <path
        fill="#212121"
        d="M5 .5a.5.5 0 0 0-1 0V4H.5a.5.5 0 0 0 0 1H4v3.5a.5.5 0 0 0 1 0V5h3.5a.5.5 0 0 0 0-1H5V.5Z"
      />
    </svg>
  )
}


const Place = ({ className = "" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16">
      <path
        fill="#212121"
        d="M10 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.6 2a.5.5 0 0 0-.6.5v11a.5.5 0 0 0 .6.5l5-1c.2 0 .4-.3.4-.5v-9c0-.2-.2-.4-.4-.5l-5-1ZM8 13V3l4 .8V12l-4 .8Zm-2 0v-1H4V4h2V3H3.5c-.3 0-.5.2-.5.5v9c0 .3.2.5.5.5H6Z"
      />
    </svg>
  )
}


const Floor = ({ className = "" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16">
      <path
        fill="#212121"
        d="M8.5 1.1a1 1 0 0 0-1 0l-6 3.5a1 1 0 0 0 0 1.8l6 3.5h1l6-3.5a1 1 0 0 0 0-1.8l-6-3.5Zm-1 1.2a1 1 0 0 1 1 0L14 5.5 8.5 8.7a1 1 0 0 1-1 0L2 5.5l5.5-3.2ZM1 7.7l6.4 3.6h1l6.4-3.6c.2.4 0 1-.4 1.2l-6 3.5a1 1 0 0 1-1 0L1.5 9A1 1 0 0 1 1 7.7ZM1 10l6.4 3.6c.3.2.7.2 1 0l6.4-3.6c.2.4 0 1-.4 1.3l-6 3.5a1 1 0 0 1-1 0l-6-3.5A1 1 0 0 1 1 10Z"
      />
    </svg>
  )
}

const Building = ({ className = "" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16">
      <path
        fill="#212121"
        d="M2.8 4.5a.8.8 0 1 0 0-1.5.8.8 0 0 0 0 1.5Zm.7 2.3a.8.8 0 1 1-1.5 0 .8.8 0 0 1 1.5 0Zm-.8 3.7a.8.8 0 1 0 0-1.5.8.8 0 0 0 0 1.5Zm3.8-6.8a.8.8 0 1 1-1.5 0 .8.8 0 0 1 1.5 0Zm-.8 3.8a.8.8 0 1 0 0-1.5.8.8 0 0 0 0 1.5Zm.8 2.3a.8.8 0 1 1-1.5 0 .8.8 0 0 1 1.5 0Zm2.3.7a.8.8 0 1 0 0-1.5.8.8 0 0 0 0 1.5ZM.4 16a.5.5 0 0 1-.5-.5v-14C0 .7.7 0 1.5 0h6C8.3 0 9 .7 9 1.5V6h1.5c.8 0 1.5.7 1.5 1.5v8c0 .3-.2.5-.5.5H.5ZM1 1.5V15h2v-2.5c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5V15h2V7.5c0-.3-.2-.5-.5-.5h-2a.5.5 0 0 1-.5-.5v-5c0-.3-.2-.5-.5-.5h-6c-.3 0-.5.2-.5.5ZM8 13H6.5v2H8v-2Zm-2.5 0H4v2h1.5v-2Z"
      />
    </svg>
  )
}


const CalendarOutline = ({ className = "" }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 16"
    >
      <path
        fill="#212121"
        d="M4 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm2-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm2-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm4-5.5C14 1.1 12.9 0 11.5 0h-9A2.5 2.5 0 0 0 0 2.5v9C0 12.9 1.1 14 2.5 14h9c1.4 0 2.5-1.1 2.5-2.5v-9ZM1 4h12v7.5c0 .8-.7 1.5-1.5 1.5h-9c-.8 0-1.5-.7-1.5-1.5V4Zm1.5-3h9c.8 0 1.5.7 1.5 1.5V3H1v-.5C1 1.7 1.7 1 2.5 1Z"
      />
    </svg>
  )
}

const DeskDot = props => {
  const {
    className = '',
    style = {},
    width = 7,
    color = "#8C8CA1"
  } = props

  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="11.5" fill={color} stroke="#fff" />
      <circle cx="11.8" cy="11.8" r="4.5" fill="#fff" />
    </svg>
  )
}

const TextDescription = props => {
  const {
    className = '',
    style = {},
    width = 7,
  } = props

  return (
    <svg
     className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      >
        <path
          fill="#242424"
          d="M1.5 3a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1h-13Zm0 3a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1h-13ZM1 9.5c0-.3.2-.5.5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5Zm.5 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9Z"
        />
    </svg>
  )
}

export {
  MarkerOutline,
  ClockOutline,
  StickerOutline,
  CarOutline,
  DeskDot,
  Car,
  Chair,
  Plus,
  Place,
  Floor,
  Building,
  CalendarOutline,
  TextDescription
}