import React, { useState, useCallback, useEffect, useRef } from "react"
import i18n from 'i18next'
import { Settings } from "luxon"

import {
  Form,
  Input,
  Button,
  FormField,
  FormLabel,
  FormMessage,
  EyeSlashIcon,
  EyeIcon,
  Alert,
  ArrowLeftIcon,
  Tooltip,
  PresenceAvailableIcon
} from '@fluentui/react-northstar'

import { useTranslation } from 'react-i18next'
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"


import { api, getBaseUrl, clearAuth } from '../../../api/api'
import { 
  deviseAuth,
  ldapAuth,
  ssoAuth,
  setLoading,
  setAlert,
  setDomain,
  sendCode,
  defaultCatch,
  defaultSuccess
} from '../../../state/actions'
import { REGEX_EMAIL } from '../../../variables'


import "./Login.css"

const usoAppSrc = "/uso-app.svg"

function Login(props) {

  const {
    goHomeMethod,
    deviseAuth,
    setLoading,
    sendCode,
    ldapAuth,
    setDomain,
    setAlert,
    loading,
    ssoAuth,
    goHome,
    defaultCatch,
    defaultSuccess
  } = props

  const { t } = useTranslation()
  const ref = useRef()
  const history = useHistory()
  const location = useLocation()

  const [slide, setSlide] = useState('welcome')
  const [authTypeData, setAuthTypeData] = useState('')
  const [baseUrl, setBaseUrl] = useState('')

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [showPasswordField, setShowPasswordField] = useState(false)
  // eslint-disable-next-line
  const [ssoURL, setSsoURL] = useState('')
  const [code, setCode] = useState('')

  const handleNavigate = useCallback(() => history.replace('/tab/load'), [history])

  useEffect(() => {
    if (!ref.current) {
      ref.current = 1
      return
    }

    if (location.pathname === "/config") goHomeMethod()
    else handleNavigate()

  }, [goHome, goHomeMethod, handleNavigate, location.pathname])


  useEffect(() => {
    const lang = 'es'
    Settings.defaultLocale = lang
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
  }, [])

  const setBackgroundColor = (color = 'white') => {
    document.getElementsByTagName('body')[0].style.background = color
  }

  const ssoAuthGenerator = useCallback((data, baseUrl) => {
    const url = ssoAuth(data, baseUrl)
    setSsoURL(url)
  }, [ssoAuth])

  const classicAuth = useCallback((data, baseUrl) => {
    setAuthTypeData(data)
    setBaseUrl(baseUrl)
    setShowPasswordField(true)
  }, [])

  const authType = useCallback(callback => {
    return api.post('/auth/type', { email })
      .then(({ data }) => {
        const { domains = [], devise, sso, ldap } = data
        const domain = domains[0]
        setDomain(domain)
        const baseUrl = getBaseUrl(domain)
        if (callback) return callback(data, baseUrl)
        if (sso) return ssoAuthGenerator(data, baseUrl)
        if (ldap || devise) return classicAuth(data, baseUrl)
      })
  }, [classicAuth, email, setDomain, ssoAuthGenerator])

  const handleEmail = useCallback(e => {
    const email = e.target.value
    setSsoURL('')
    setPassword('')
    setEmailError('')
    setPasswordError('')
    setShowPasswordField('')
    clearAuth()
    setEmail(email)
  }, [])

  const handleCode = useCallback(e => {
    setCode(e.target.value)
  }, [])

  const evalEmail = useCallback(() => {
    let error = ''

    if (
      email &&
      !REGEX_EMAIL.test(email)
    ) {
      error = t('invalid email')
    }

    setEmailError(error)
    return error
  }, [email, t])

  const isDisabled = useCallback(
    () => loading || !(email && password),
    [email, loading, password])

  const isDisabledNext = useCallback(
    () => loading || !!emailError || !email,
    [email, emailError, loading]
  )

  const handleTypeAuth = useCallback(() => {
    const emailError = evalEmail()
    if (emailError) return
    setLoading(true)
    authType()
      .finally(() => setLoading(false))
  }, [authType, evalEmail, setLoading])

  const handlePassword = e => {
    setPasswordError('')
    setPassword(e.target.value)
  }

  const loginCatch = useCallback(e => {
    if (e?.response?.status === 401) {
      const errors = e?.response?.data?.errors
      if (Array.isArray(errors)) setPasswordError(errors)
      else setPasswordError(t('password is incorrect'))
    } else if (e?.response?.message) {
      setPasswordError(e?.response?.message)
    } else if (e?.message) {
      setPasswordError(t(e?.message))
    } else setPasswordError(t('unknown error'))
  }, [t])


  const handleClassicAuth = useCallback(() => {
    const { devise, ldap } = authTypeData

    setLoading(true)

    if (ldap) {
      return ldapAuth({ password }, authTypeData)
        .catch(loginCatch)
        .finally(() => setLoading(false))
    }

    if (devise) {
      return deviseAuth({ email, password }, baseUrl)
        .catch(loginCatch)
        .finally(() => setLoading(false))
    }
  }, [authTypeData, baseUrl, deviseAuth, email, ldapAuth, loginCatch, password, setLoading])


  const handleKeyPress = useCallback(event => {
    if (event.key !== 'Enter') return
    if (isDisabled()) return
    handleClassicAuth()
  }, [handleClassicAuth, isDisabled])


  const handleEmailKeyPress = useCallback(event => {
    if (
      !email ||
      event.key !== 'Enter' ||
      loading ||
      showPasswordField
    ) return

    handleTypeAuth()
  }, [email, handleTypeAuth, loading, showPasswordField])


  const handleLogin = useCallback(e => {
    if (showPasswordField) handleClassicAuth()
    else handleTypeAuth()
  }, [handleClassicAuth, handleTypeAuth, showPasswordField])

  const WelcomeBox = useCallback(() => {
    return (
      <div className="welcome-box">
        <img src={usoAppSrc} alt="login-icon" />
        <h1>{t('we welcome you')}</h1>
        <p>{t('with Corporate Experience you can manage the reservations of services that you will use the days you go to the office.')}</p>
        <Button content={t('login')} flat primary onClick={() => setSlide('login')} />
        <br />
        <br />
        <Tooltip
          content={
            <>
              {t('signup detail')}
              <a target="_blank" href="https://en.parso.co/contact" rel="noreferrer">
                https://en.parso.co/contact
              </a >
            </>
          }
          trigger={
            <Button
              size="small"
              text
            >
              {t('signup')}
            </Button>
          }
        />
        <label className="version">v 1.2</label>
      </div>
    )
  }, [t])


  const validateTokenSSO = useCallback(() => {  
    const params = { email }
    setLoading(true)
    api.post('/sac/send', params)
      .then(({ data }) => {
        setLoading(false)
        if (data.success) setSlide('code')
    })
  }, [email, setLoading])


  const getButtons = useCallback(() => {
    if (showPasswordField) return (
      <>
        <Button
          content={t('did you forget your password')}
          text
          primary
          onClick={() => setSlide('reset')}
        />
        <Button
          className="main-button"
          content={t("login")}
          flat
          primary
          onClick={handleLogin}
          disabled={isDisabled()}
        />
      </>
    )

    return (
      <Button
        className="main-button"
        content={t("next")}
        flat
        primary
        onClick={handleLogin}
        disabled={isDisabledNext()}
      />
    )
  }, [handleLogin, isDisabled, isDisabledNext, showPasswordField, t])


  const toggleShowPassword = useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword])

  const backButton = () => {
    setSsoURL('')
    setPassword('')
    setEmailError('')
    setPasswordError('')
    setShowPasswordField('')
    clearAuth()
    setSlide('welcome')
  }

  const classicLoginForm = useCallback(() => {
    return (
      <div className="welcome-box form">
        <Button
          onClick={backButton}
          className="back-button"
          icon={<ArrowLeftIcon />}
          iconOnly
          title="Back"
        />
        <Form
          className="login-form"
          onSubmit={e => e.preventDefault()}
        >
          <h1>{t('login')}</h1>
          <FormField>
            <FormLabel htmlFor="email" id="email-label">
              {t('User')}
            </FormLabel>
            <Input
              error={!!emailError}
              required
              id="email"
              type="email"
              name="email"
              placeholder={t('enter your email')}
              onChange={handleEmail}
              showSuccessIndicator={false}
              onKeyPress={handleEmailKeyPress}
              value={email}
            />
            <FormMessage id="email-error" role="alert" error>
              {emailError}
            </FormMessage>
          </FormField>
          {showPasswordField &&
            <FormField>
              <FormLabel htmlFor="password" id="password-label">
                {t('Password')}
              </FormLabel>
              <Input
                error={!!passwordError}
                required
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder={t('enter your password')}
                showSuccessIndicator={false}
                onChange={handlePassword}
                onKeyPress={handleKeyPress}
                value={password}
                icon={
                  <Button
                    onClick={toggleShowPassword}
                    icon={
                      showPassword ?
                        <EyeSlashIcon /> :
                        <EyeIcon />
                    }
                    text
                    iconOnly
                    title="show password"
                  />
                }
              />
              <FormMessage id="password-error" role="alert" error>
                {passwordError}
              </FormMessage>
            </FormField>
          }
          {getButtons()}
        </Form>
      </div>
    )
  }, [email, emailError, getButtons, handleEmail, handleEmailKeyPress, handleKeyPress, password, passwordError, showPassword, showPasswordField, t, toggleShowPassword])


  const ssoLoginForm = useCallback(() => {
    return (
      <div className="welcome-box form">
        <Button
          onClick={backButton}
          className="back-button"
          icon={<ArrowLeftIcon />}
          iconOnly
          title="Back"
        />
        <Form
          className="login-form"
          onSubmit={validateTokenSSO}
        >
          <h2>{t('login')}</h2>
          <FormField>
            <FormLabel htmlFor="email" id="email-label">
              {t('User')}
            </FormLabel>
            <Input
              required
              id="email"
              type="email"
              name="email"
              onChange={handleEmail}
              showSuccessIndicator={false}
              onKeyPress={handleEmailKeyPress}
              value={email}
            />
          </FormField>
          <Button
            className="main-button"
            disabled={!email}
            content={t('login with SSO')}
            primary
          />
        </Form>
      </div>
    )
  }, [validateTokenSSO, email, handleEmail, handleEmailKeyPress, t])


  const LoginBox = useCallback(() => {
    setBackgroundColor('#eeeeee')
    if (ssoURL) return ssoLoginForm()
    return classicLoginForm()
  }, [classicLoginForm, ssoLoginForm, ssoURL])

  const sendResetPasswordMail = useCallback(() => {
    setLoading(true)
    authType(() =>
      api.post('/password/reset', { email })
        .then(defaultSuccess)
        .then(() => {
          setAlert({
            open: true,
            content: t("we have sent you an email to x to change your password", { x: email }),
            success: true,
            autoHideDuration: 2000
          })
          setSlide('welcome')
        })
        .catch(error => defaultCatch(error, history))
    )

  }, [setLoading, authType, email, defaultSuccess, setAlert, t, defaultCatch, history])


  const ResetBox = useCallback(() => {
    return (
      <div className="welcome-box form">
        <Button
          onClick={() => setSlide('login')}
          className="back-button"
          icon={<ArrowLeftIcon />}
          iconOnly
          title="Back"
        />
        <Form
          className="login-form"
          onSubmit={sendResetPasswordMail}
        >
          <h2>{t('Restore password')}</h2>
          <p>{t('We will send you the instructions to the email so you can reset your password.')}</p>
          <FormField>
            <FormLabel htmlFor="email" id="email-label">
              {t('User')}
            </FormLabel>
            <Input
              required
              type="email"
              name="email"
              id="email"
              disabled
              onChange={handleEmail}
              showSuccessIndicator={false}
              value={email}
            />
          </FormField>
          <Button
            disabled={!email}
            content={t('Send instructions')}
            flat
            primary
          />
        </Form>
      </div>
    )
  }, [email, handleEmail, sendResetPasswordMail, t])

  const CodeBox = useCallback(() => {
    return (
      <div className="welcome-box form">
        <Button
          onClick={() => setSlide('login')}
          className="back-button"
          icon={<ArrowLeftIcon />}
          iconOnly
          title="Back"
        />
        <Form
          className="login-form"
          onSubmit={() => sendCode(code, email, t)}
        >
          <h2>{t('Almost ready')}</h2>
          <Alert className="code-alert" icon={<PresenceAvailableIcon />} success >
            {t('To complete your Microsoft Teams login, we sent an authentication code to you email. Please check your inbox and enter your code below.')}
            <br/>
            <br/>
            {t('The code is valid for 10 minutes.')}
          </Alert>

          <FormField>
            <FormLabel htmlFor="email" id="email-label">
              {t('Access code')}
            </FormLabel>
            <Input
              required
              type="password"
              name="code"
              id="code"
              onChange={handleCode}
              showSuccessIndicator={false}
              value={code}
            />
          </FormField>
          <Button
            disabled={!code}
            content={t('Enter the code')}
            flat
            primary
          />
        </Form>
      </div>
    )
  }, [code, email, handleCode, sendCode, t])

  const getSlide = useCallback(() => {
    if (slide === 'welcome') return WelcomeBox()
    if (slide === 'login') return LoginBox()
    if (slide === 'reset') return ResetBox()
    if (slide === 'code') return CodeBox()
  }, [LoginBox, ResetBox, WelcomeBox, CodeBox, slide])


  return (
    <div className="welcome-wrapper" >
      {getSlide()}
    </div>
  )
}

const mapDispatchToProps = {
  sendCode,
  deviseAuth,
  ldapAuth,
  ssoAuth,
  setLoading,
  setAlert,
  setDomain,
  defaultSuccess,
  defaultCatch
}


const mapStateToProps = state => {
  return {
    goHome: state.profile.goHome,
    loading: state.backdrop.loading
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)