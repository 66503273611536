import React, { useState, useEffect, useCallback, useMemo } from 'react'

import {
  Form,
  FormDropdown,
  FormDatepicker,
  Flex,
  Dialog,
  CloseIcon,
  InfoIcon,
  FormInput,
  FormTextArea,
  Button,
  Pill,
  Input,
} from '@fluentui/react-northstar'

import { DateTime } from 'luxon'

import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import { api } from '../../../api/api'

import { Types } from "../../../state/actionTypes"
import NewReservationLayout from '../../NewReservationLayout/NewReservationLayout'


import {
  errorCreateReservationWorkplaceI18n,
} from '../../../common/utilities/Dictionary'

import {
  formatDateToISOShort,
  formatTime,
  getCurrentHour,
  isToday
} from '../../../common/utilities/FormatDate'

import {
  getWorkplaceDefaultEntryTime,
  getWorkplaceDefaultExitTime
} from '../../../common/utilities/utilities'

import {
  ClockOutline,
  CalendarOutline,
  StickerOutline,
  Place,
  TextDescription
} from '../../Svgs/Svgs'


import {
  DEFAULT_OFFSET_MIN_RESERVATION_MINUTES,
  EIGHT_AM,
  END_DAY,
  FIVE_PM
} from '../../../variables'

import {
  _dispatch as dispatch,
  setLoading,
  defaultSuccess,
  defaultCatch,
} from '../../../state/actions'

import Hide from '../../Hide/Hide'
import './WorkplaceReservation.css'
import RoomsModal from '../../RoomsModal/RoomsModal'
const today = new Date()

function WorkplaceReservation(props) {
  const {
    dispatch,
    setLoading,
    defaultSuccess,
    defaultCatch,
    askForRoomReservationNotes,
    workplaceDefaultEntryTime,
    workplaceDefaultExitTime,
    minReservationTime,
    unformattedDefaultEntryTime,
    unformattedDefaultExitTime,
    enabledWorkplaceRoomReservationsAttendees
  } = props

  const { t } = useTranslation()
  const DEFAULT_ROOM = useMemo(() => ({ id: -1, name: t('select') }), [t])

    
  const reasons = [
    t('private meeting'),
    t('workday'),
    t('training'),
    t('special event'),
    t('others')
  ]
  
  const history = useHistory()
  const location = useLocation()
  const initDate = location?.state?.previousDate || today 
  const [date, setDate] = useState(initDate)
  const [roomAttendees, setRoomAttendees] = useState([])
  const [emailFormatError, setEmailFormatError] = useState(false)
  const [emailAlreadyInList, setEmailAlreadyInList] = useState(false)
  const [roomAttendeeEmail, setRoomAttendeeEmail] = useState('')

  const maxFutureDays = useMemo(() => location?.state?.maxFutureDays || 0, [location])


  const handleAddRoomAttendeeKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(roomAttendeeEmail)) {
        if (roomAttendeeEmail.length > 0 && roomAttendees.includes(roomAttendeeEmail)) {
          setEmailAlreadyInList(true)
          setEmailFormatError(false)
        } else {
          setRoomAttendees([...roomAttendees, roomAttendeeEmail])
          setRoomAttendeeEmail('')
          setEmailFormatError(false)
          setEmailAlreadyInList(false)
          dispatch({
            type: Types.SET_ALERT_DATA,
            payload: {
              open: true,
              content: t('Attendee added'),
              success: true,
              autoHideDuration: 4000
            }
          })
        }
      } else if (roomAttendeeEmail.length > 0 && !emailRegex.test(roomAttendeeEmail)) {
        setEmailFormatError(true)
      }
    }
  }, [dispatch, roomAttendeeEmail, roomAttendees, t]);


  const getDefaultEntryTime = useCallback(date => {
    const isTodayValue = isToday(date || initDate)
    if (workplaceDefaultEntryTime && !isTodayValue)
      return workplaceDefaultEntryTime

    if (isTodayValue) {
      const minutes = minReservationTime + DEFAULT_OFFSET_MIN_RESERVATION_MINUTES
      const minHour = getCurrentHour({ plusHour: 0, plusMinutes: minutes})
      return workplaceDefaultEntryTime &&
        workplaceDefaultEntryTime >= minHour ?
        workplaceDefaultEntryTime :
        minHour
    }
    return EIGHT_AM;
  }, [initDate, workplaceDefaultEntryTime, minReservationTime])
  const [entry_time, setEntryTime] = useState(getDefaultEntryTime(date))

  const getDefaultExitTime = useCallback(entry => {
    if (workplaceDefaultExitTime && workplaceDefaultExitTime > entry) return workplaceDefaultExitTime
    return END_DAY
  }, [workplaceDefaultExitTime])
  
  const [exit_time, setExitTime] = useState(getDefaultExitTime())
  const [allTime, setAllTime] = useState(false)

  const [rooms, setRooms] = useState([])
  const [room, setRoom] = useState('')
  const [reason, setReason] = useState(t('workday'))
  const [openRoomsList, setOpenRoomsList] = useState(false)
  const [notes, setNotes] = useState('')
  const onCancel = () => history.push('/')

  const cleanRooms = useCallback((rooms = []) => {
    const items = []
    const ids = []
    rooms.forEach(r => {
      if (!ids.includes(r.id)) {
        ids.push(r.id)
        items.push(r)
      }
    })
    return items
  }, [])

  const getFormateTimeWorkplace = useCallback(() => {
    if (allTime) return t('all day')
    if (entry_time && exit_time) return `${formatTime(entry_time)} - ${formatTime(exit_time)}`
    return t('select')
  }, [allTime, entry_time, exit_time, t])


    const fetchAvailableRooms = useCallback(date => {
    setLoading(true)
    const _date = formatDateToISOShort(date)
    const params = { date: _date, all: true }
    api.get(`workplace/rooms/availability`, { params })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const rooms = cleanRooms(data.rooms)
        setRooms(rooms)
      })
      .catch(error => defaultCatch(error, history))
  }, [setLoading, defaultSuccess, cleanRooms, defaultCatch, history])


  useEffect(() => {
    fetchAvailableRooms(date)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isEnabled = useCallback(() => {
    return (
      date &&
      (allTime || (entry_time && exit_time)) &&
      room.id > -1
    )
  }, [entry_time, exit_time, date, allTime, room])


  const getMaxDateWorkplace = useCallback(() => {
    return DateTime
      .local()
      .plus({ days: maxFutureDays })
      .toJSDate()
  }, [maxFutureDays])

  const updateReason = (_, { value }) => setReason(value)
  const updateNotes = (_, { value }) => setNotes(value)

  const clearNoTimeFields = () => {
    setRoom('')
  }

  const resetTimes = (value = date) => {
    setEntryTime(getDefaultEntryTime(value))
    setExitTime(getDefaultExitTime())
  }

  const updateDate = (_, { value }) => {
    setDate(value)    
    resetTimes(value)
    fetchAvailableRooms(value)
    clearNoTimeFields()
  }

  const onCancelMarker = useCallback(() => {
    setOpenRoomsList(false)
  }, [])

  const saveReservation = useCallback(() => {
    setLoading(true)
    let _entry_time = entry_time
    let _exit_time = exit_time

    if (allTime) {
      _entry_time = getDefaultEntryTime(date)
      _exit_time = getDefaultExitTime(_entry_time)
    }

    const payload = {
      room_reservation: {
        reservation_date: date,
        room_id: room.id,
        entry_time: _entry_time,
        exit_time: _exit_time,
        reason,
        notes,
        attendees: roomAttendees.length > 0 ? roomAttendees : []
      }
    }

    api.post('/workplace/room_reservation', payload)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success && data.message) {
          dispatch({
            type: Types.SET_ALERT_DATA,
            payload: {
              open: true,
              content: errorCreateReservationWorkplaceI18n(data.message),
              danger: true,
              autoHideDuration: 4000
            }
          })
        } else {
          history.push('/')
          dispatch({
            type: Types.SET_ALERT_DATA,
            payload: {
              open: true,
              content: t('success room reservation'),
              success: true,
              autoHideDuration: 4000
            }
          })
        }
      })
      .catch(error => defaultCatch(error, history))

  }, [allTime, date, defaultCatch, defaultSuccess, dispatch, entry_time, exit_time, getDefaultEntryTime, getDefaultExitTime, history, notes, reason, room, roomAttendees, setLoading, t])



  const onContinueRoom = useCallback(params => {
    const { entryTime, exitTime, allTime = false, selectedRoom } = params
    setAllTime(allTime)
    setEntryTime(entryTime)
    setExitTime(exitTime)
    setRoom(selectedRoom)
    setOpenRoomsList(false)
  }, [])

    const renderRoomAttendeesErrorMessages = useCallback(() => {
    if (emailFormatError) {
      return (
        <p className="attendees-error-message">
          {t('The email address entered is not valid.')}
        </p>
      )
    }
    if (emailAlreadyInList) {
      return (
        <p className="attendees-error-message">
          {t('The email address is already in the list.')}
        </p>
      )
    }
  }, [emailAlreadyInList, emailFormatError, t]);

  const handleAddRoomAttendee = useCallback((event) => {
    setRoomAttendeeEmail(event.target.value)
  }, []);

  const onDismissAttendee = index => {
    setRoomAttendees(roomAttendees.filter((_, i) => i !== index))
  }

  return (
    <NewReservationLayout
      title={t('New reservation: room')}
      onCancel={onCancel}
      >
      <div className="NewReservation">
        <Form>
          <Flex gap="gap.small" vAlign='center'>
            <CalendarOutline className='input-icon' />
            <label className='form-label'>{t('date')}</label>
            <FormDatepicker
              onDateChange={updateDate}
              selectedDate={date}
              minDate={today}
              maxDate={getMaxDateWorkplace()}
              inputPlaceholder={t('date')}
              allowManualInput={false}
              inputOnly
            />
          </Flex>
          <Flex gap="gap.small" vAlign='center'>
            <Place className='input-icon' />
            <label className='form-label'>{t('room')}</label>
            <Dialog
              open={openRoomsList}
              onOpen={() => setOpenRoomsList(true)}
              onCancel={onCancelMarker}
              header={
                <div className="detail-map-header">
                  <h1>{t('Select a room')}</h1>
                </div>
              }
              headerAction={{
                icon: <CloseIcon />,
                title: 'Close',
                onClick: onCancelMarker,
              }}
              content={
                <RoomsModal
                  onCancelRoom={() => setOpenRoomsList(false)}
                  onContinueRoom={onContinueRoom}
                  setEntryTime={setEntryTime}
                  setExitTime={setExitTime}
                  defaultEntryTime={unformattedDefaultEntryTime}
                  defaultExitTime={unformattedDefaultExitTime}
                  defaultRoom={DEFAULT_ROOM}
                  rooms={rooms}
                  date={date}
                />
              }
              trigger={                
                <FormInput
                  placeholder={t('room')}
                  showSuccessIndicator={false}
                  value={room.name}
                  inverted
                />
              }
            />
          </Flex>
          <Flex
            gap="gap.small"
            vAlign="center"
          >
            <ClockOutline className='input-icon' />
            <label className='form-label'>{t('schedule')}</label>
            <FormInput
              placeholder={t('hour')}
              value={getFormateTimeWorkplace()}
              inverted
            />
          </Flex>
          <Flex gap="gap.small" vAlign='center'>
            <TextDescription className='input-icon' />
            <label className='form-label'>{t('reason')}</label>
            <FormDropdown
              items={reasons}
              placeholder={t('reason')}
              onChange={updateReason}
              value={reason}
              inverted
              fluid
            />
          </Flex>
           <Hide hide={!!askForRoomReservationNotes}>                   
            <Flex gap="gap.small" vAlign='center'>
              <StickerOutline className='input-icon' />
              <label className='form-label'>{t('notes')}</label>
              <FormTextArea
                placeholder={t('notes')}
                onChange={updateNotes}
                value={notes}
                inverted
              />
            </Flex>
          </Hide>
          <Hide hide={!enabledWorkplaceRoomReservationsAttendees}>   
            <Flex gap="gap.small" vAlign='start'>
              <StickerOutline className='input-icon' />
              <label className='form-label'>{t('Meeting attendees (Optional)')}</label>
              <Flex
                column
                gap="gap.smaller"
              >
                <Input
                  className='input-attendees'
                  inverted
                  placeholder={t('Enter a name or e-mail address')}
                  value={roomAttendeeEmail}
                  onChange={handleAddRoomAttendee}
                  onKeyPress={handleAddRoomAttendeeKeyPress}
                />
                {renderRoomAttendeesErrorMessages()}
                <Flex
                  gap="gap.small"
                  vAlign='center'
                  className='info-attendees'
                >
                  <InfoIcon />
                  <p>{t('When you add guests, we will send them an email with the details of the meeting.')}</p>
                </Flex>
                {roomAttendees.map((attendee, index) =>
                  <Pill
                    className='attendee-chip'
                    key={index}
                    actionable
                    onDismiss={() => onDismissAttendee(index)}
                  >
                    {attendee}
                  </Pill>
                )}
              </Flex>
            </Flex>
          </Hide>
          <Flex gap="gap.small" hAlign="end">
            <Button
              disabled={!isEnabled()}
              content={t('confirm reservation')}
              primary
              onClick={saveReservation}
            />
          </Flex>
        </Form>
      </div>
    </NewReservationLayout>
  )
}

const mapStateToProps = state => {
  return {
    enabledWorkplaceRoomReservationsAttendees: state.profile.company.enabled_workplace_room_reservations_attendees,
    askForRoomReservationNotes: state.profile.company.ask_for_room_reservation_notes,
    unformattedDefaultEntryTime: state.profile.company.workplace_default_entry_time || EIGHT_AM,
    unformattedDefaultExitTime: state.profile.company.workplace_default_exit_time || FIVE_PM,
    minReservationTime: state.profile.company.min_reservation_time,
    workplaceDefaultEntryTime: getWorkplaceDefaultEntryTime(state),
    workplaceDefaultExitTime: getWorkplaceDefaultExitTime(state),
  }
}

const mapDispatchToProps = {
  dispatch,
  setLoading,
  defaultSuccess,
  defaultCatch
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceReservation)
