import { Button, Flex, CircleIcon } from '@fluentui/react-northstar'
import { useTranslation } from 'react-i18next'
import { getLocalitation } from '../../common/utilities/utilities'
import { NOT_AVAILABLE } from '../../variables'
import './RoomCard.css'

function RoomCard(props) {
  const {
    room,
    status = {},
    selectedRoom = {},
    setSelectedRoom,
    handleOpenRoomDetails,
    handleSeeWhoBooked,
  } = props

  const { t } = useTranslation()

  return (
    <div
      className={`RoomCard ${selectedRoom.id === room.id ? 'selected' : ''}`}
      onClick={() => setSelectedRoom({ label: status.label, ...room })}
      >
        <Flex
          gap='gap.small'
          vAlign='center'
          space='between'
        >
          <h1>{room?.name}</h1>
          <Flex
            gap='gap.small'
            vAlign='center'
          >
            <CircleIcon
              size='small'
              className={`status-icon ${status.className}`}
            />
            <label>{status.message}</label>
          </Flex>
        </Flex>
        <Flex space="between">
          <div className="info">
            {`${t('Location')}: ${getLocalitation(room)}`}
          </div>
        </Flex>
        <Flex gap='gap.small' vAlign='center' hAlign='center'>
          <Button
            text
            onClick={() => handleOpenRoomDetails()}
          >
            {t('See details')}
          </Button>
          {
            status.label === NOT_AVAILABLE && (room?.usage && room?.usage[0]?.user?.first_name) !== '' &&
            <Button
              className="see-who-booked"
              onClick={() => {
                setSelectedRoom(room)
                handleSeeWhoBooked()
              }}
            >
              {t('See who booked')}
            </Button>
          }
        </Flex>
    </div>
  )
}

export default RoomCard
