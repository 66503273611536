const AirConditioner = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      title="AirConditioner"
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      width={width}
    >
      <path fill="#6F6F6F" d="M6.5 13.667v-3.25L4.017 12.9l-.717-.7L6.5 9V7.5H5l-3.133 3.133-.734-.683 2.45-2.45H.333v-1h3.25L1.083 4l.717-.717L5 6.5h1.5V4.983L3.367 1.85l.7-.733L6.5 3.567V.333h1v3.234l2.5-2.5.7.716-3.2 3.2V6.5h1.517l3.15-3.15.716.7-2.45 2.45h3.234v1h-3.234L12.9 9.983l-.683.717-3.2-3.2H7.5V9l3.2 3.217-.683.716L7.5 10.417v3.25h-1Z" />

    </svg>
  )
}

const Blackboard = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      title="Blackboard"
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 12 12"
      width={width}
    >
      <path fill="#6F6F6F" d="M1 12a1 1 0 0 1-.7-.3A1 1 0 0 1 0 11V1C0 .7.1.5.3.3.5.1.7 0 1 0h10c.3 0 .5.1.7.3.2.2.3.4.3.7v10c0 .3-.1.5-.3.7a1 1 0 0 1-.7.3H1Zm0-1h10V1H1v10Zm1-1h4.2V6.7H2V10Zm4.7-5.6H10V2H6.7v2.4ZM2 6.2h4.2V2H2v4.2ZM6.7 10H10V4.9H6.7V10Z" />
    </svg>
  )
}

const Plug = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      title="Plug"
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 12 12"
      width={width}
    >
      <path fill="#6F6F6F" d="M10 8V7h1.5a.5.5 0 0 1 .5.5l-.1.3-.4.2H9.9Zm0 2.7v-1h1.5a.5.5 0 0 1 .5.5l-.1.3-.4.2H9.9Zm-2.7.6a1 1 0 0 1-.8-.4c-.2-.2-.3-.5-.3-.9H4.6V7.7h1.6c0-.4 0-.7.3-1 .2-.2.5-.4.8-.4h2.1v5h-2Zm-4.7-2c-.8 0-1.4-.2-1.9-.7-.5-.5-.7-1-.7-1.9a2.5 2.5 0 0 1 2.6-2.5h1c.4 0 .7-.2 1-.4.2-.2.3-.5.3-.9s-.1-.7-.3-.9c-.3-.2-.6-.3-1-.3H1.3c-.2 0-.3 0-.4-.2a.5.5 0 0 1-.1-.3V.8l.4-.1h2.5a2.2 2.2 0 0 1 2.2 2.2 2.2 2.2 0 0 1-2.2 2.3H2.6c-.5 0-.9.1-1.2.4-.3.3-.4.7-.4 1.1 0 .5.1 1 .4 1.2.3.3.7.4 1.2.4H4v1H2.6Z" />
    </svg>
  )
}

const Projector = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      title="Projector"
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 12 11"
    >
      <path fill="#6F6F6F" d="M11.45 3.8a1.03 1.03 0 0 0-1.05.1l-.91.64a2.46 2.46 0 0 0-2.5-2.15h-.03L4.9.42A1.52 1.52 0 0 0 3.84 0H.5C.22 0 0 .21 0 .48c0 .26.22.48.5.48h3.34c.13 0 .25.05.35.14l1.35 1.3H2.5A2.45 2.45 0 0 0 0 4.77V8.6A2.45 2.45 0 0 0 2.5 11H7c1.28 0 2.36-.93 2.49-2.15l.91.65c.44.32 1.07.23 1.4-.2a.9.9 0 0 0 .2-.57V4.66c0-.36-.21-.7-.55-.86ZM8.5 8.6c0 .8-.67 1.44-1.5 1.44H2.5c-.83 0-1.5-.64-1.5-1.43V4.78c0-.79.67-1.43 1.5-1.43H7c.83 0 1.5.64 1.5 1.43v3.83Zm2.5.13L9.5 7.66V5.73L11 4.66v4.07Z" />
    </svg>
  )
}

const VideoConference = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      title="VideoConference"
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 12 9"
      width={width}
    >
      <path fill="#6F6F6F" d="M.9 9a.9.9 0 0 1-.63-.25.79.79 0 0 1-.27-.6V.86C0 .61.09.41.27.24A.9.9 0 0 1 .9 0h7.8a.9.9 0 0 1 .63.25.8.8 0 0 1 .27.6v3.02L12 1.62v5.76L9.6 5.13v3.03a.8.8 0 0 1-.27.59.9.9 0 0 1-.63.25H.9Zm0-.84h7.8V.84H.9v7.32Z" />
    </svg>
  )
}

const Wifi = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      title="Wifi"
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 13"
      width={width}
    >
      <path fill="#6F6F6F" d="M3.87 8.05 2.82 7a8.45 8.45 0 0 1 2.44-1.75A6.51 6.51 0 0 1 8 4.67c.98 0 1.9.2 2.74.58.85.39 1.67.97 2.44 1.75l-1.05 1.05a6.52 6.52 0 0 0-2.05-1.45 5.35 5.35 0 0 0-4.16 0 6.3 6.3 0 0 0-2.05 1.45ZM1.05 5.23 0 4.18a12.35 12.35 0 0 1 3.6-2.55A10.2 10.2 0 0 1 8 .67c1.56 0 3.02.32 4.4.96 1.36.65 2.57 1.5 3.6 2.55l-1.05 1.05A12.36 12.36 0 0 0 11.75 3 8.56 8.56 0 0 0 8 2.17 8.5 8.5 0 0 0 4.26 3a12.6 12.6 0 0 0-3.21 2.23ZM8 12.18l2.47-2.48A3.33 3.33 0 0 0 8 8.67 3.33 3.33 0 0 0 5.53 9.7L8 12.18Z" />
    </svg>
  )
}

const Monitor = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      title="Monitor"
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 12"
      width={width}
    >
      <path fill="#6F6F6F" d="M4.5 12v-1.33H1.33c-.26 0-.5-.1-.7-.3a.96.96 0 0 1-.3-.7V1c0-.27.1-.5.3-.7.2-.2.44-.3.7-.3h11.34c.26 0 .5.1.7.3.2.2.3.43.3.7v8.67c0 .26-.1.5-.3.7-.2.2-.44.3-.7.3H9.5V12h-5ZM1.33 9.67h11.34V1H1.33v8.67Z" />
    </svg>
  )
}

const StandDesk = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 17 17">
      <path fill="#323232" d="M4.02 5.11a1.14 1.14 0 1 0 0-2.28 1.14 1.14 0 0 0 0 2.28Zm7.21 1.52h1.52v6.83h-1.52z" />
      <path fill="#323232" d="M7.44 6.63h7.59v1.52H7.44z" />
      <path fill="#323232" fillRule="evenodd" d="M11.23 13.46h1.52-1.52Zm1.52 0h2.28v-1.52h-2.28v1.52Zm-1.52 0v-1.52H5.16V9.66h.76V6.63a.76.76 0 0 0-.76-.76H2.88a.76.76 0 0 0-.75.76v3.03h.75v3.8h8.35Z" clipRule="evenodd" />
    </svg>
  )
}

const DoubleMonitor = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 15">
      <g clipPath="url(#a)">
        <path fill="#323232" d="M13.13 1.25H1.87C1.2 1.25.63 1.81.63 2.5V10c0 .69.57 1.25 1.25 1.25h4.38v1.25H5v1.25h5V12.5H8.75v-1.25h4.38c.68 0 1.24-.56 1.24-1.25V2.5c0-.69-.56-1.25-1.24-1.25Zm0 8.75H1.87V2.5h11.25V10Z" />
      </g>
      <g clipPath="url(#b)">
        <path fill="#323232" d="M30.13 1.25H18.88c-.7 0-1.25.56-1.25 1.25V10c0 .69.56 1.25 1.25 1.25h4.37v1.25H22v1.25h5V12.5h-1.25v-1.25h4.38c.68 0 1.25-.56 1.25-1.25V2.5c0-.69-.57-1.25-1.25-1.25Zm0 8.75H18.88V2.5h11.25V10Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h15v15H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" d="M17 0h15v15H17z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const WideDesk = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 13 9">
      <path fill="#323232" d="M5 5.13h2.5v1.25L9.37 4.5 7.5 2.63v1.25H5V2.63L3.12 4.5 5 6.38V5.13ZM12.32.75a.63.63 0 0 0-.62-.63H.7a.63.63 0 0 0-.63.63v7.5c0 .34.29.63.63.63h11c.34 0 .62-.29.62-.63V.75Zm-1.25 6.88H1.32V1.38h9.75v6.25Z" />
    </svg>
  )
}

const Print = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 14">
      <path fill="#323232" d="M13.25 4h-.75V.25h-9V4h-.75C1.5 4 .5 5 .5 6.25v4.5h3v3h9v-3h3v-4.5C15.5 5 14.5 4 13.25 4ZM5 1.75h6V4H5V1.75Zm6 10.5H5v-3h6v3Zm1.5-3v-1.5h-9v1.5H2v-3c0-.41.34-.75.75-.75h10.5c.41 0 .75.34.75.75v3h-1.5Z" />
    </svg>
  )
}

const ErgonomicChair = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2478_68142)">
        <path d="M3.12067 3.29094C2.59567 2.91761 2.46734 2.19427 2.83484 1.66344C3.20234 1.13844 3.9315 1.01011 4.46234 1.37761C4.98734 1.75094 5.11567 2.47427 4.74817 3.00511C4.37484 3.53011 3.6515 3.65844 3.12067 3.29094ZM9.33317 11.0843H5.209C4.34567 11.0843 3.61067 10.4543 3.48234 9.6026L2.33317 4.08427H1.1665L2.32734 9.77761C2.549 11.2009 3.774 12.2509 5.21484 12.2509H9.33317V11.0843ZM9.46734 8.75094H6.62067L6.01984 6.35927C6.9415 6.87844 7.93317 7.25761 9.024 7.07094V5.82844C8.07317 6.00927 7.01734 5.67094 6.28817 5.09927L5.3315 4.35844C5.19734 4.25344 5.04567 4.18344 4.88817 4.13677C4.7015 4.08427 4.50317 4.06677 4.31067 4.10177H4.299C3.5815 4.23011 3.10317 4.91261 3.22567 5.62427L4.01317 9.07761C4.1765 9.90594 4.894 10.5009 5.734 10.5009H9.72984L11.9582 12.2509L12.8332 11.3759L9.46734 8.75094Z" fill="#323232" />
      </g>
      <defs>
        <clipPath id="clip0_2478_68142">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

const Window = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 15 15">
      <path fill="#323232" d="M13.04.38H1.96c-.87 0-1.58.7-1.58 1.58v11.08c0 .87.7 1.59 1.58 1.59h11.08a1.6 1.6 0 0 0 1.59-1.59V1.96A1.6 1.6 0 0 0 13.04.38Zm0 12.66H1.96V1.96h11.08v11.08ZM4.78 2.75H2.75v2.04c1.12 0 2.03-.92 2.03-2.04Zm2.72 0H6.15a3.4 3.4 0 0 1-3.4 3.4V7.5A4.75 4.75 0 0 0 7.5 2.75Zm1.7 4.64-2.38 3.06-1.7-2.04-2.37 3.05h9.5L9.19 7.39Z" />
    </svg>
  )
}

const NaturalLight = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.225 3.025L3.1 1.90625L2.21875 2.7875L3.3375 3.90625L4.225 3.025ZM0.625 6.5625H2.5V7.8125H0.625V6.5625ZM6.875 0.34375H8.125V2.1875H6.875V0.34375ZM11.9 1.90313L12.78 2.7825L11.6613 3.90125L10.7819 3.02125L11.9 1.90313ZM10.775 11.35L11.8938 12.475L12.775 11.5938L11.65 10.475L10.775 11.35ZM12.5 6.5625H14.375V7.8125H12.5V6.5625ZM7.5 3.4375C5.43125 3.4375 3.75 5.11875 3.75 7.1875C3.75 9.25625 5.43125 10.9375 7.5 10.9375C9.56875 10.9375 11.25 9.25625 11.25 7.1875C11.25 5.11875 9.56875 3.4375 7.5 3.4375ZM7.5 9.6875C6.11875 9.6875 5 8.56875 5 7.1875C5 5.80625 6.11875 4.6875 7.5 4.6875C8.88125 4.6875 10 5.80625 10 7.1875C10 8.56875 8.88125 9.6875 7.5 9.6875ZM6.875 12.1875H8.125V14.0313H6.875V12.1875ZM2.21875 11.5875L3.1 12.4688L4.21875 11.3438L3.3375 10.4625L2.21875 11.5875Z" fill="#323232" />
    </svg>
  )
}

const MacStation = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 15 15">
      <path fill="#323232" d="M3.54 13.13h.71c.43 0 .73-.21.83-.6l.18-.6h4.48l.18.6c.1.39.4.6.83.6h.7c.68 0 1.05-.4 1.05-1.09V2.96c0-.7-.37-1.08-1.04-1.08h-.71c-.43 0-.73.2-.83.59l-.18.6H5.26l-.18-.6c-.1-.4-.4-.6-.83-.6h-.7c-.68 0-1.05.4-1.05 1.09v9.08c0 .7.37 1.09 1.04 1.09Zm.12-.94c-.14 0-.22-.08-.22-.21V3.02c0-.13.08-.21.22-.21h.44c.09 0 .15.04.18.13l.2.7c.07.25.23.37.52.37h5c.29 0 .45-.12.51-.36l.21-.71a.17.17 0 0 1 .18-.13h.44c.14 0 .22.08.22.2v8.97c0 .13-.08.2-.22.2h-.44a.17.17 0 0 1-.18-.12l-.2-.7c-.07-.25-.23-.37-.52-.37H5c-.29 0-.45.12-.51.36l-.21.71a.17.17 0 0 1-.18.13h-.44Zm3.9-5.8c.15 0 .36-.12.48-.27.1-.13.18-.3.18-.5v-.06c-.18 0-.4.11-.53.26a.8.8 0 0 0-.2.49c0 .02 0 .05.02.06l.04.01Zm-.58 2.7c.22 0 .32-.14.6-.14.27 0 .34.14.58.14.25 0 .4-.22.56-.43.18-.25.24-.5.24-.51a.77.77 0 0 1-.09-1.38.86.86 0 0 0-.7-.36c-.28 0-.5.16-.64.16-.15 0-.34-.15-.58-.15-.45 0-.9.36-.9 1.06 0 .42.17.87.38 1.16.18.25.33.45.55.45Z" />
    </svg>
  )
}

const Footrest = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 13 11">
      <path fill="#fff" d="m9.7 6.2-.98.97.3.48 1.04-1.07-.36-.39Z" />
      <path fill="#323232" d="M2.16 10.35H1a.71.71 0 0 1-.54-.24.72.72 0 0 1-.2-.56l.38-4.43c.02-.19.1-.35.24-.48a.7.7 0 0 1 .49-.2h7.51c.2 0 .36.07.5.2.13.13.21.3.23.48l.38 4.43c.02.22-.05.4-.2.56a.7.7 0 0 1-.54.24H2.16ZM1 9.61h8.25l-.37-4.43H1.36L.99 9.61Z" />
      <path fill="#323232" d="m10.06 6.58 2.24-2.24.45.46-2.23 2.23-.46-.45ZM1.8 9.24V7.83a.86.86 0 0 1 .57-.81 4.42 4.42 0 0 0 1.18-.68l.58.58c.02.04.06.06.12.06a.2.2 0 0 0 .15-.06.15.15 0 0 0 .04-.13c0-.06-.02-.1-.04-.14l-.53-.55.23-.2.23-.26.52.53c.03.04.07.06.13.06a.2.2 0 0 0 .15-.07.15.15 0 0 0 .04-.12c0-.06-.02-.1-.04-.14l-.56-.56.2-.27c.06-.09.12-.2.16-.3l.64.66c.02.05.07.07.12.06.06 0 .11-.02.15-.07a.17.17 0 0 0 .05-.12.3.3 0 0 0-.04-.15l-.76-.75.76-1.35L8.4.5 11.9 4 6.64 9.24H1.8Zm.76-.73h3.78L10.85 4 8.4 1.54 6.43 3.5l-.42.77.3.31c.18.2.28.42.3.68a.81.81 0 0 1-.25.65l-1.5 1.5a.89.89 0 0 1-.65.27.84.84 0 0 1-.63-.27l-.13-.12c-.14.09-.3.17-.44.23l-.46.2v.78Z" />
      <path fill="#fff" d="M6.17 4.44s.35.24.43.74l2.33.74.68-.68L9.58 4l-3.41.45ZM5.08 4.4l.76.76s.1.16 0 .26-.28 0-.28 0l-.64-.66s-.15.4-.36.58c-.33.04-.41 0-.42-.03 0-.03-.05-.1 0-.13 0 0 .08 0 .1-.03l.05-.04c.08-.08.14-.2.23-.35.18-.3.1-.67.1-.81l.46.45Z" />
    </svg>
  )
}

const Keyboard = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 15 15">
      <path fill="#323232" d="M2.19 11.88a.89.89 0 0 1-.66-.3.9.9 0 0 1-.28-.64V4.06c0-.25.1-.47.28-.65a.9.9 0 0 1 .66-.29H12.8c.25 0 .47.1.66.29.19.18.28.4.28.65v6.88a.9.9 0 0 1-.28.65.9.9 0 0 1-.66.29H2.2Zm0-.94H12.8V4.06H2.2v6.88Zm2.5-1.02h5.62v-.94H4.7v.94ZM3.17 7.97h.94v-.94h-.94v.94Zm1.94 0h.94v-.94H5.1v.94Zm1.92 0h.94v-.94h-.94v.94Zm1.94 0h.94v-.94h-.94v.94Zm1.92 0h.94v-.94h-.94v.94ZM3.17 6.02h.94v-.94h-.94v.94Zm1.94 0h.94v-.94H5.1v.94Zm1.92 0h.94v-.94h-.94v.94Zm1.94 0h.94v-.94h-.94v.94Zm1.92 0h.94v-.94h-.94v.94Z" />
    </svg>
  )
}

const AdditionalTable = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 15 15">
      <path fill="#323232" d="M2.28 5.94H12.7l-.7-2.5H3.02l-.74 2.5Zm8.53.93h-6.6l-.23 1.57h7.04l-.2-1.56ZM2.5 12.5l.77-5.63h-1.4a.57.57 0 0 1-.49-.25.62.62 0 0 1-.1-.54l.9-3.13a.66.66 0 0 1 .21-.33.6.6 0 0 1 .38-.12h9.46c.15 0 .28.04.38.13.1.08.18.19.22.32l.89 3.13a.6.6 0 0 1-.1.54.57.57 0 0 1-.5.25h-1.37l.75 5.63h-.94l-.42-3.13H3.86l-.42 3.13H2.5Z" />
    </svg>
  )
}

const ProjectionScreen = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 15 15">
      <path fill="#323232" d="M2.81 13.13a.9.9 0 0 1-.66-.28.9.9 0 0 1-.27-.66V2.8a.9.9 0 0 1 .27-.66.9.9 0 0 1 .66-.27h9.38a.9.9 0 0 1 .66.27c.18.18.28.4.28.66v9.38a.9.9 0 0 1-.28.66.9.9 0 0 1-.66.28H2.8Zm0-.94h9.38V4.06H2.8v8.13Zm4.7-1.64a3.94 3.94 0 0 1-3.67-2.42A3.92 3.92 0 0 1 7.49 5.7a3.94 3.94 0 0 1 3.67 2.42 3.92 3.92 0 0 1-3.67 2.43Zm-.01-.78a3.2 3.2 0 0 0 2.81-1.64A3.2 3.2 0 0 0 7.5 6.49a3.2 3.2 0 0 0-2.81 1.63A3.13 3.13 0 0 0 7.5 9.77Zm0-.86c.22 0 .4-.08.55-.23a.76.76 0 0 0 .23-.56c0-.21-.07-.4-.23-.55a.76.76 0 0 0-.55-.23c-.22 0-.4.08-.55.23a.76.76 0 0 0-.23.56c0 .21.07.4.23.55.15.15.33.23.55.23Z" />
    </svg>
  )
}

const ExpandableRoom = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      title="ExpandableRoom"
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 22 13"
      width={width}
    >
      <path fill="#6F6F6F" d="M7.5 12C7.225 12 6.98958 11.8898 6.79375 11.6695C6.59792 11.4492 6.5 11.1844 6.5 10.875V4.125C6.5 3.81563 6.59792 3.55078 6.79375 3.33047C6.98958 3.11016 7.225 3 7.5 3H15.5C15.775 3 16.0104 3.11016 16.2063 3.33047C16.4021 3.55078 16.5 3.81563 16.5 4.125V10.875C16.5 11.1844 16.4021 11.4492 16.2063 11.6695C16.0104 11.8898 15.775 12 15.5 12H7.5ZM7.5 10.875H8.5V4.125H7.5V10.875ZM9.5 10.875H13.5V4.125H9.5V10.875ZM14.5 10.875H15.5V4.125H14.5V10.875Z"></path>
      <path fill="#6F6F6F" d="M4.5 9L1.5 7.5L4.5 6V9ZM21.5 7.5L18.5 9V6L21.5 7.5Z"></path>
    </svg>
  )
}

const Phone = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      title="Phone"
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 13 12"
      width={width}
    >
      <path fill="#6F6F6F" d="M10.475 10.5C9.43333 10.5 8.40417 10.2729 7.3875 9.81875C6.37083 9.36458 5.44583 8.72083 4.6125 7.8875C3.77917 7.05417 3.13542 6.12917 2.68125 5.1125C2.22708 4.09583 2 3.06667 2 2.025C2 1.875 2.05 1.75 2.15 1.65C2.25 1.55 2.375 1.5 2.525 1.5H4.55C4.66667 1.5 4.77083 1.53958 4.8625 1.61875C4.95417 1.69792 5.00833 1.79167 5.025 1.9L5.35 3.65C5.36667 3.78333 5.3625 3.89583 5.3375 3.9875C5.3125 4.07917 5.26667 4.15833 5.2 4.225L3.9875 5.45C4.15417 5.75833 4.35208 6.05625 4.58125 6.34375C4.81042 6.63125 5.0625 6.90833 5.3375 7.175C5.59583 7.43333 5.86667 7.67292 6.15 7.89375C6.43333 8.11458 6.73333 8.31667 7.05 8.5L8.225 7.325C8.3 7.25 8.39792 7.19375 8.51875 7.15625C8.63958 7.11875 8.75833 7.10833 8.875 7.125L10.6 7.475C10.7167 7.50833 10.8125 7.56875 10.8875 7.65625C10.9625 7.74375 11 7.84167 11 7.95V9.975C11 10.125 10.95 10.25 10.85 10.35C10.75 10.45 10.625 10.5 10.475 10.5ZM3.5125 4.5L4.3375 3.675L4.125 2.5H3.0125C3.05417 2.84167 3.1125 3.17917 3.1875 3.5125C3.2625 3.84583 3.37083 4.175 3.5125 4.5ZM7.9875 8.975C8.3125 9.11667 8.64375 9.22917 8.98125 9.3125C9.31875 9.39583 9.65833 9.45 10 9.475V8.375L8.825 8.1375L7.9875 8.975Z"></path>
    </svg>
  )
}




export {
  StandDesk,
  DoubleMonitor,
  WideDesk,
  Print,
  ErgonomicChair,
  Window,
  NaturalLight,
  MacStation,
  Footrest,
  Keyboard,
  AdditionalTable,
  ProjectionScreen,
  AirConditioner,
  Blackboard,
  Plug,
  Projector,
  VideoConference,
  Wifi,
  Monitor,
  ExpandableRoom,
  Phone
}