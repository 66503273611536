import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Loader } from '@fluentui/react-northstar'

import { sortItems } from '../../../common/utilities/utilities'
import ParsoIco from '../../../common/components/Svgs/ParsoIco'
import { api } from '../../../api/api'
import { Types } from '../../../state/actionTypes'
import { _dispatch as dispatch, defaultCatch, defaultSuccess } from '../../../state/actions'
import './Load.css'

function Load(props) {
  const {
    dispatch,
    defaultCatch,
    defaultSuccess
  } = props

  const history = useHistory()
  const _defaultCatch = error => defaultCatch(error, history)

  const fetchSitesForRooms = () => {
    return api.get('/workplace/sites', { params: { desks: false, rooms: true } })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        dispatch({
          type: Types.SET_SITES_ROOMS,
          payload: sortItems(data.sites)
        })
      })
      .catch(_defaultCatch)
  }

  const fetchSitesForDesk = () => {
    return api.get('/workplace/sites', { params: { desks: true, rooms: false } })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        dispatch({
          type: Types.SET_SITES,
          payload: sortItems(data.sites)
        })
      })
      .catch(_defaultCatch)
  }

  useEffect(() => {
    Promise.all([fetchSitesForRooms(), fetchSitesForDesk()]).then(() => {
      history.push('/tab/')
    }).catch(error => console.error("Error fetching data", error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='Load'>
      <div className='box-loading'>
        <ParsoIco width={75} />
        <Loader secondary size="largest" />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    enabledWorkplace: state.profile.enabled_workplace,
    enabledRoom: state.profile.enabled_room_reservations,
    enabledDesk: state.profile.company.enabled_desk_reservations,
  }
}

const mapDispatchToProps = {
  dispatch,
  defaultSuccess,
  defaultCatch
}

export default connect(mapStateToProps, mapDispatchToProps)(Load)
